import React, {useEffect, useState} from "react";
import {Form, ConfigProvider, theme, Input, Button, Card, Checkbox, Table, Tag} from "antd";
import {error, success, warning} from "../common/toastr";
import axios from "axios";
import {config} from "../../config";

const AppleMusic = () => {
    const { Meta } = Card;
    const [activeTab, setActiveTab] = useState("song");
    const [songUrl, setSongUrl] = useState("");
    const [playlistUrl, setPlaylistUrl] = useState("");
    const [searchText, setSearchText] = useState("");
    const [data, setData] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);


    // const data = [
    //     { key: '1', title: 'Choice A' },
    //     { key: '2', title: 'Choice B' },
    //     { key: '3', title: 'Choice C' },
    //     { key: '4', title: 'Choice D' },
    //     // Add more choices as needed
    // ];

    useEffect(() => {
        // console.log('Fetching blog post...');
        const fetchBlogData = () => {
            axios.get(`${config.baseUrl}/blog/names`).then((response) => {
                setData(response.data);
            }).catch((error) => {
                console.error('Error fetching blog post:', error);
            })
        };
        const fetchFeaturedPostsId = () => {
            axios.get(`${config.baseUrl}/admin/featured-posts`, {
                headers: {
                    "token": localStorage.getItem("token")
                }
            }).then((response) => {
                setSelectedItems(response.data);
            }).catch((error) => {
                console.error('Error fetching blog post:', error);
            })
        };
        fetchBlogData();
        fetchFeaturedPostsId();
    }, []);

    // const [selectedItems, setSelectedItems] = useState([]);


    // const handleClick = (item) => {
    //     if (selectedItems.length < 3 && !selectedItems.includes(item)) {
    //         setSelectedItems([...selectedItems, item]);
    //     } else if (selectedItems.includes(item)) {
    //         setSelectedItems(selectedItems.filter((selected) => selected !== item));
    //     } else {
    //         // Handle case where user tries to select more than 3 items
    //         // Maybe show a message or something
    //     }
    // };


    const getColor = (num) => {
        if(num == 1)
        {
            return "red"
        }
        else if(num == 2)
        {
            return "blue"
        }
        else if(num == 3)
        {
            return "green"
        }
        else
        {
            return "yellow"
        }
    }

    const columns = [
        {
            title: (<Checkbox disabled={true}/>),
            dataIndex: 'selection',
            width: 25,
            render: (_, record) => (
                <Checkbox
                    checked={selectedItems.includes(record.key)}
                    onChange={() => handleCheckboxChange(record.key)}
                >
                </Checkbox>
            ),
        },
        {
            title: 'Title',
            dataIndex: 'title',
            width: 250
        },
        {
            title: "Priority",
            dataIndex: 'selection',
            width: 50,
            render: (_, record) => {
                return selectedItems.includes(record.key) ? (<Tag color={getColor(selectedItems.indexOf(record.key) + 1)}>{selectedItems.indexOf(record.key) + 1}</Tag>) : null
            },
        }
    ];

    const handleSearch = (value) => {
        setSearchText(value);
    };

    const filteredData = data.filter(item =>
        item.title.toLowerCase().includes(searchText.toLowerCase())
    );


    const handleCheckboxChange = (item) => {
        if (selectedItems.length < 3 && !selectedItems.includes(item)) {
            setSelectedItems([...selectedItems, item]);
        } else if (selectedItems.includes(item)) {
            setSelectedItems(selectedItems.filter((selected) => selected !== item));
        } else {
            warning("Too many choices");
        }
    };


    const handleInputChange = (e) => {
        if (activeTab === "song" && e.target.value.startsWith('https://embed.music.apple.com/')) {
            setSongUrl(e.target.value);
        }
        if (activeTab === "playlist" && e.target.value.startsWith('https://embed.music.apple.com/')) {
            setPlaylistUrl(e.target.value);
        }
    }

    const onSubmit = () => {
        if (selectedItems.length > 0)
        {
            // const object = {
            //     "first": selectedItems[0] ? selectedItems[0] : null,
            //     "second": selectedItems[1] ? selectedItems[1] : null,
            //     "third": selectedItems[2] ? selectedItems[2] : null
            // }
            // console.log("Submitted : ", object)

            axios.post(config.baseUrl + "/admin/featured-posts", selectedItems,{
                headers: {
                    token: localStorage.getItem("token")
                }
            }).then(response => {
                // // console.log("Response", response.data.success)
                if(response.data.status === "success")
                {
                    success("Added Featured Posts successfully")
                }
                else {
                    error("Something went wrong")
                }
            }).catch(exception => {
                console.error(exception)
                error(exception)
            })
        }
        else
        {
            error("Not enough choices");
        }
    }

    const submitPlaylist = () => {
        // console.log("Playlist : ", playlistUrl)
        if(playlistUrl.startsWith('https://embed.music.apple.com/'))
        {
            const requestBody = {
                id: 1,
                url: playlistUrl
            }
            axios.post(config.baseUrl + "/admin/applemusic", requestBody,{
                headers: {
                    token: localStorage.getItem("token")
                }
            }).then(response => {
                // // console.log("Response", response.data.success)
                if(response.data.status === "success")
                {
                    success("Added Playlist successfully")
                }
                else {
                    error("Something went wrong")
                }
            }).catch(exception => {
                console.error(exception)
                error(exception)
            })
        }
    }

    const submitSong = () => {
        if(songUrl.startsWith('https://embed.music.apple.com/'))
        {
            const requestBody = {
                id: 2,
                url: songUrl
            }
            axios.post(config.baseUrl + "/admin/applemusic", requestBody,{
                headers: {
                    token: localStorage.getItem("token")
                }
            }).then(response => {
                // // console.log("Response", response.data.success)
                if(response.data.status === "success")
                {
                    success("Added Song successfully")
                }
                else {
                    error("Something went wrong")
                }
            }).catch(exception => {
                console.error(exception)
                error(exception)
            })
        }
    }

    return (
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center"}}>
            <Card
                style={{ width: '100%', maxWidth: '800px' }}
                tabList={[
                    { key: 'song', tab: 'Song' },
                    { key: 'playlist', tab: 'Playlist' },
                    { key: 'featuredposts', tab: 'Featured Posts'}
                ]}
                size={"default"}
                activeTabKey={activeTab}
                bordered={false}
                onTabChange={(key) => setActiveTab(key)}
            >
                <Form
                    colon={false}
                    size={"default"}
                    name="music-form"
                    requiredMark={false}
                    layout='vertical'
                >
                    {activeTab === "song" ? (
                        <>
                            <Form.Item label="Song URL" name="songUrl">
                                <Input 
                                    value={songUrl}
                                    onChange={handleInputChange}
                                />
                            </Form.Item>
                            <Form.Item label="Song Preview" name="preview">
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <iframe
                                        title="Apple Music Song"
                                        allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write"
                                        frameBorder="0"
                                        height="187"
                                        style={{width: '100%', maxWidth: '660px', overflow: 'hidden', borderRadius: '10px'}}
                                        sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
                                        src={songUrl}
                                    ></iframe>
                                </div>
                            </Form.Item>
                            <Form.Item>
                                <Button size={"default"} onClick={() => submitSong()}>Submit</Button>
                            </Form.Item>
                        </>
                    ) : null}

                    {activeTab === "playlist" ? (
                        <>
                            <Form.Item label="Playlist URL" name="playlistUrl">
                                <Input 
                                    onChange={handleInputChange}
                                    // name="name"
                                    value={playlistUrl}
                                />
                            </Form.Item>
                            <Form.Item label="Playlist Preview" name="preview">
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <iframe
                                        title="Apple Music Playlist"
                                        allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write"
                                        frameBorder="0"
                                        height="450"
                                        style={{width: '100%', maxWidth: '660px', overflow: 'hidden', borderRadius: '10px'}}
                                        sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
                                        src={playlistUrl}
                                    ></iframe>
                                </div>
                            </Form.Item>
                            <Form.Item>
                                <Button size={"default"} onClick={() => submitPlaylist()}>Submit</Button>
                            </Form.Item>
                            {/* Add more fields as needed for album information */}
                        </>
                    ) : null}
                    {activeTab === "featuredposts" ? (
                        <>
                            <Input.Search
                                placeholder="Search by title"
                                size={"default"}
                                onSearch={(text) => handleSearch(text)}
                                onChange={(e) => handleSearch(e.target.value)}
                                style={{ marginBottom: '25px', maxWidth: "300px" }}
                            />
                            <Form.Item>
                                <Table
                                    columns={columns}
                                    dataSource={filteredData}
                                    pagination={false}
                                    // showHeader={true}
                                    scroll={{ x: 600, y: "39vh" }}
                                    style={{width: "100%"}}
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button size={"default"} onClick={() => onSubmit()}>Submit</Button>
                            </Form.Item>
                        </>
                    ) : null}
                </Form>
            </Card>
        </div>
    );
};


export default AppleMusic;
