import React, {useEffect, useRef, useState} from 'react';
import {BrowserRouter as Router, Routes, Route, Link, Navigate, useLocation, useNavigate} from 'react-router-dom';
import {Provider, useDispatch, useSelector} from 'react-redux';
import {ConfigProvider, Divider, Layout, Menu, Result, Spin, theme} from 'antd';
import { config } from '../../config';
import '../styles.css'; // Import your custom styles
import store from '../redux/store';
import { success, warning } from '../common/toastr';
import TopBar from '../common/TopBar';
import LoginButton from '../Login/LoginButton';
import LoginBlocker from '../common/LoginBlocker';
import RouteBlocker from '../common/RouteBlocker';
import AdminLayout from '../Admin/AdminLayout';
import Home from '../Misc/Home';
import Blog from '../Blog/Blog';
import BlogPost from '../Blog/BlogPost';
import Legal from './Legal';
import LoginPage from '../Login/LoginPage';
import RegisterPage from '../Register/RegisterPage';
import AddPost from '../Blog/AddPost';
import LayoutBlocker from "../common/LayoutBlocker";
import CurrentProfile from "../Misc/CurrentProfile";
import {Alert, createTheme, ThemeProvider} from "@mui/material";
import {Content, Header} from "antd/es/layout/layout";
import {
    AreaChartOutlined,
    CommentOutlined,
    DesktopOutlined,
    HomeOutlined, LogoutOutlined,
    MenuOutlined, MoonOutlined, PoweroffOutlined,
    ReadOutlined, SettingOutlined, SunOutlined, UserOutlined
} from "@ant-design/icons";
import Sider from "antd/es/layout/Sider";
import {PostAddOutlined} from "@mui/icons-material";
import permissionChecker from "../common/permissionChecker";
import {logoutUser} from "../redux/tokenSlice";
import LoginChecker from "../common/LoginChecker";
import Settings from "../common/Settings";
import { createGlobalStyle } from 'styled-components';

const MainLayout = () => {
    const dispatch = useDispatch()
    const redux = useSelector((state) => state)
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 800); // Set initial value based on screen width
    const [closed, setClosed] = useState(false);
    const [selectedMenuItem, setSelectedMenuItem] = useState("1"); // Default to Dashboard
    const [collapsed, setCollapsed] = useState(true);
    const [rightCollapsed, setRightCollapsed] = useState(true);
    const navigate = useNavigate();
    const anotherSider = useRef(null);
    const [menu, setMenu] = useState([]);
    const [mode, setMode] = useState(localStorage.getItem("mode"));
    const [reRender, setRerender] = useState(1);

    const GlobalStyle = createGlobalStyle`
      body, header, .main-content{
        color: black;
        background-color: #F9F7F7;
        border: none;
        /* Add other global styles here */
      }
      
      .recharts-default-tooltip{
          background-color: #F9F7F7 !important;
      }
      button {
          //color: black !important;
          //background-color: ;
      }
      header
      {
          border-bottom: 1px solid black !important;
          border-radius: 0px;
      }
      footer
      {
          border-top: 1px solid black !important;
          border-radius: 0px !important;
      }
      .blog-title
      {
          color: #1963b3 !important;
      }
      .ant-divider{
          display: none;
      }
      .ant-layout-content, footer,  .left-menu, .right-menu, .ant-layout
      {
          color: black !important;
          border: none;
          background-color: #F9F7F7 !important;
      }
      .ant-card {
          background-color: #F9F7F7 !important;
          border-color: black !important;
      }
      a{
          color: #1963b3
      }
      span{
          color: #121212
      }
      .ant-notification-notice-wrapper {
          background: white;
      }
      .recharts-tooltip-item-name{
          color: inherit;
      }
      .logo{
          filter: invert(100%);
      }
      .bordered-sider {
          border-right: 1px solid black !important;
      }
      .ant-select-selector{
          background-color: transparent !important;
      }
      .ant-notification {
          //position: fixed;
          //top: 20px;
          //right: 20px;
          //width: 300px;
          //z-index: 1000;
      }
      
      

      .ant-notification-notice {
          border: 1px solid #ccc !important;
          border-radius: 6px;
          background-color: #ffffff; /* Adjusted to a darker shade */
      }

      .ant-notification-notice-close {
          color: #000000 !important;
      }
      
      //input, textarea {
      //    color: black !important;
      //}

      .ant-notification-notice-message {
          color: #000000 !important;
      }

      .ant-notification-notice-description {
          color: #000000 !important;
      }
      
      p {
          color: black !important;
      }
      
      .first-card {
          box-shadow: none !important;
          //border: 10px solid black;
      }
      
      .ant-card-head {
          border-bottom: 1px solid black !important;
      }
      
      .ant-card-actions {
          background-color: transparent !important;
          border-top: 1px solid black !important;
      }
      
      h5 {
          color: #404040 !important;
      }
      button span {
          color: inherit !important;
      }
    `;

    useEffect(() => {
        setMode(localStorage.getItem("mode"))
    }, [localStorage.getItem("mode")]);
    // const navigate = useNavigate();


    const items = [
        {
            label: 'Home',
            key: 'home',
            icon: <HomeOutlined />,
        },
        {
            label: 'Blog',
            key: 'blog',
            icon: <ReadOutlined />
        },
        // {
        //     label: 'Game',
        //     key: 'game',
        //     icon: <AimOutlined />
        // },
        {
            label: 'Post',
            key: 'post',
            icon: <PostAddOutlined />,
            hidden: !permissionChecker("blogs/post")
        },
        {
            label: 'Admin',
            key: 'admin',
            icon: <DesktopOutlined />,
            hidden: !permissionChecker("admin")
        },
        {
            label: localStorage.getItem('mode') ? (localStorage.getItem('mode') === "light" ? 'Dark Mode' : 'Light Mode') : 'Light Mode',
            key: localStorage.getItem('mode') ? (localStorage.getItem('mode') === "light" ? 'darkmode' : 'lightmode') : 'lightmode',
            icon: localStorage.getItem('mode') ? (localStorage.getItem('mode') === "light" ? <MoonOutlined /> : <SunOutlined />) : <SunOutlined />,
            hidden: localStorage.getItem("token")
        }
    ];


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 800);
            setCollapsed(true);
            setRightCollapsed(true);
        };

        // Attach the event listener
        window.addEventListener("resize", handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    const NotFound = () => {
        return (
            <div>
                <Result
                    // status="404"
                    icon={<img src={process.env.PUBLIC_URL + '/icons/404.svg'} style={{maxHeight: 500, maxWidth: 500}}/>}
                    title="404"
                    subTitle="Sorry, the page you visited does not exist."
                />
            </div>)
    };

    const Forbidden = () => {
        return (
            <div>
                <div style={{width: "100%"}}>
                    <Result
                        // status="403"
                        icon={<img src={process.env.PUBLIC_URL + '/icons/403.svg'} style={{maxHeight: 500, maxWidth: 500}}/>}
                        title="403"
                        style={{width: "100%"}}
                        subTitle="Sorry, you don't have permission to visit that page."
                    />
                </div>
            </div>)
    }

    const Error = () => {
        return (
            <div>
                <Result
                    // status="500"
                    icon={<img src={process.env.PUBLIC_URL + '/icons/500.svg'} style={{maxHeight: 500, maxWidth: 500}}/>}
                    title="500"
                    subTitle="An unknown error occurred"
                />
            </div>)
    }

    const Unauthorized = () => {
        return (
            <div>
                <Result
                    // status="500"
                    icon={<img src={process.env.PUBLIC_URL + '/icons/401.svg'} style={{maxHeight: 500, maxWidth: 500}}/>}
                    title="401"
                    subTitle="You don't have authorization to view this page"
                />
            </div>)
    }

    const profile = () => {
        setCollapsed(true);
        setRightCollapsed(true);
        navigate('/profile/' + redux.token.username)
    }

    const settings = () => {
        setCollapsed(true);
        setRightCollapsed(true);
        navigate('/settings')
    }


    const logout = () => {
        setCollapsed(true);
        setRightCollapsed(true);
        try
        {
            localStorage.clear();
        }
        catch(error)
        {
            console.error(error)
            warning("You have disabled local storage. Login won't work.")
        }
        navigate('/')
        dispatch(logoutUser())
        success("Logged out successfully")
        // window.location.reload()
    }

    const toggleSidebar = () => {
        setCollapsed(!collapsed);
        setRightCollapsed(true);
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            const toggleButton = document.querySelector(".your-custom-button-class"); // Replace with the actual class of your custom button
            const leftSider = document.querySelector(".left-sider")
            const rightSider = document.querySelector(".othersider")
            const rightSiderButton = document.querySelector(".right-sider")
            // console.log("What doesn't kill you makes you stronger")
            // Check if the click occurred outside the Sider and not within the custom button
            if (
                // anotherSider.current &&
                // !anotherSider.current.contains(event.target) &&
                toggleButton && (!toggleButton || !toggleButton.contains(event.target)) &&
                leftSider && (!leftSider || !leftSider.contains(event.target)) &&
                rightSider && (!rightSider || !rightSider.contains(event.target)) &&
                rightSiderButton && (!rightSiderButton || !rightSiderButton.contains(event.target)) &&
                isMobile
            ) {
                // console.log("Stand a little taller")
                setCollapsed(true); // Collapse the Sider
                setRightCollapsed(true);
            }
        };

        // Attach the event listener to the document
        document.addEventListener("click", handleOutsideClick);

        // Clean up the event listener on component unmount
        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, [anotherSider, isMobile]);


    const handleMenuClick = (key) => {
        setSelectedMenuItem(key);
    };

    const onClick = (e) => {
        // console.log(e);
        if(e == "home")
        {
            navigate("/")
            setCollapsed(true);
        }
        else if (e == "darkmode")
        {
            localStorage.setItem("mode", "dark");
            setRerender((prevArg) => {return prevArg + 1});
            setCollapsed(true);
        }
        else if (e == "lightmode")
        {
            localStorage.setItem("mode", "light");
            setRerender((prevArg) => {return prevArg + 1});
            setCollapsed(true);
        }
        else
        {
            navigate("/" + e)
            setCollapsed(true);
        }
    };

    const isTokenPresent = () => {
        try {
            const token = localStorage.getItem('token');
            return !!token;
        } catch (error) {
            warning("Localstorage is disabled. Login won't work.")
            return false;
        }
    }

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    });

    const lightTheme = createTheme({
        palette: {
            mode: 'light',
        },
    });

    // console.log("Mode : ", mode);

    return (
        <div>
            <ThemeProvider theme={mode ? (mode === "dark" ? darkTheme : lightTheme) : darkTheme}>
                <ConfigProvider theme={{ algorithm: mode ? (mode === "dark" ? theme.darkAlgorithm : theme.defaultAlgorithm) : theme.darkAlgorithm, token: {colorPrimary: "#3CA8FF"} }}>
                    {mode === "light" ? <GlobalStyle/> : null}
                    <div className="container">
                            <Routes>
                                <Route path="/admin" element={<RouteBlocker searchString={"admin"}> <AdminLayout /> </RouteBlocker>} />
                            </Routes>
                            <LayoutBlocker>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    {config.env.includes('dev') && !closed && (
                                        <Alert
                                            severity="error"
                                            style={{width: "100%", marginTop: "5px"}}
                                            // variant={"outlined"}
                                            onClose={() => {
                                                setClosed(true)
                                            }}
                                        >
                                            Warning: You are in a development environment! If you see this message on
                                            nogoat.in, contact me on Github(NoGoat).
                                        </Alert>
                                    )}
                                </div>
                                <Layout style={{ minHeight: '-webkit-fill-available'}}>
                                    <Sider ref={anotherSider} tooltipVisible={!isMobile} trigger={null} width={150} collapsible collapsed={collapsed} collapsedWidth={0} style={{height: '100%', background: "#212121", border: "none", zIndex: isMobile ? 2 : 0, display: isMobile ? "flex" : "none", justifyContent: "center", flexDirection: "column", position: "fixed" }} breakpoint="md" className={"left-sider"}>
                                    <Menu theme={"dark"} mode="inline" _internalDisableMenuItemTitleTooltip={true} defaultSelectedKeys={[null]} selectable={false} style={{background: "#212121", border: "none", height: "100%", overflow: "auto", display: "flex", justifyContent: "center", flexDirection: "column", minHeight: '-webkit-fill-available'}} className={"left-menu"}>
                                            {items.map(item => (
                                                !item.hidden && (
                                                    <Menu.Item key={item.key} icon={item.icon} onClick={() => {onClick(item.key)}}>
                                                        {item.label}
                                                    </Menu.Item>
                                                )
                                            ))}

                                        </Menu>
                                    </Sider>

                                    <Content style={{background: "#121212", height: "100vh", overflow: !collapsed || !rightCollapsed ? "hidden" : "initial"}} className={"content"}>
                                        <Spin
                                            spinning={!collapsed || !rightCollapsed}
                                            fullscreen={true}
                                            style={{zIndex: 1}}
                                            indicator={null}
                                            onTouchStart={() => {
                                                setCollapsed(true)
                                                setRightCollapsed(true)
                                            }}
                                        />
                                        <header>
                                            {/*<h1>NoGoat</h1>*/}
                                            {isMobile ?
                                                <>
                                                    <div style={{ textAlign: "left", height: "50px", width: "50px", cursor: "pointer"}} onClick={toggleSidebar} className={"your-custom-button-class"}>
                                                        {/*<TopBar/>*/}
                                                        <MenuOutlined className={"your-custom-button-class"} style={{marginTop: "18px", marginLeft: "18px"}}/>
                                                    </div>
                                                    <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center',marginBottom: '0px' }}>
                                                        <img src={process.env.PUBLIC_URL + '/logo512.png'} alt="Logo" style={{ width: "64px", height: "64px", cursor: "pointer" }} onClick={() => {onClick("home")}} className={"logo"}/>
                                                    </div>
                                                    <div style={{marginBottom: !isMobile ? "0px" : null, height: "50px", width: "50px", textAlign: "right", cursor: "pointer"}}>
                                                        <LoginButton
                                                            // setMenu={setMenu}
                                                            collapsed={setRightCollapsed}
                                                            otherCollapsed={setCollapsed}
                                                        />
                                                    </div>
                                                </>:
                                                <>
                                                    <img src={process.env.PUBLIC_URL + '/logo512.png' }
                                                         style={{width: "64px", height: "64px", cursor: "pointer"}}
                                                         onClick={() => {onClick("home")}}
                                                         className={"logo"}
                                                    />
                                                    <div style={{flex: "1", textAlign: "right"}}>
                                                        <TopBar renderArg={setRerender}></TopBar>
                                                    </div>
                                                    <nav>
                                                        <LoginButton
                                                            // setMenu={setMenu}
                                                            collapsed={setRightCollapsed}
                                                            otherCollapsed={setCollapsed}
                                                        />
                                                    </nav>
                                                </>
                                            }
                                        </header>
                                        {isMobile ? <Divider type={"horizontal"} style={{borderTopColor: "#424242", marginBottom: "0px", marginTop: "0px", padding: "0px"}}></Divider> : null}

                                        <section className="main-content" style={{minHeight: isMobile ? "calc(100dvh - 183px)" : "calc(100dvh - 295px)"}}>
                                            <Routes>
                                                {/*<Route path="/" element={<Navigate to="/blog"/>}/>*/}
                                                <Route path="/" element={<Home></Home>}/>
                                                <Route path="/login" element={<LoginBlocker> <LoginPage/> </LoginBlocker>}/>
                                                <Route path="/blog" element={<Blog/>}/>
                                                <Route path="/blog/:id" element={<BlogPost/>}/>
                                                <Route path="/profile/:username" element={<CurrentProfile/>}/>
                                                <Route path="/legal" element={<Legal/>}/>
                                                <Route path="/post" element={<RouteBlocker searchString={"blogs/post"}> <AddPost/></RouteBlocker>}/>
                                                <Route path="/settings" element={<LoginChecker> <Settings renderArg={setRerender}/></LoginChecker>}/>
                                                <Route path="/register" element={<LoginBlocker> <RegisterPage/> </LoginBlocker>}/>
                                                <Route path="/notfound" element={<NotFound/>}/>
                                                <Route path="/forbidden" element={<Forbidden/>}/>
                                                <Route path="/unauthorized" element={<Unauthorized/>}/>
                                                <Route path="/error" element={<Error/>}/>
                                                <Route path="*" element={<NotFound></NotFound>}/>

                                            </Routes>
                                        </section>

                                        {isMobile ? <Divider type={"horizontal"} style={{borderTopColor: "#424242", marginBottom: "0px", marginTop: "0px", padding: "0px"}}></Divider> : null}

                                        <footer
                                            style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexShrink: 0}}
                                            className="custom-footer"
                                        >
                                            {isMobile ? <small>&copy; 2023-2024 NoGoat. All rights reserved.</small> : <p>&copy; 2023-2024 NoGoat. All rights reserved.</p>}
                                            <nav>
                                                <ul>
                                                    <li>{isMobile ? <small><Link to="/legal">Legal</Link></small> : <Link to="/legal">Legal</Link>}</li>
                                                </ul>
                                            </nav>
                                        </footer>
                                        <div style={{minHeight: isMobile ? 0 : 1}}></div>
                                    </Content>
                                    <Sider ref={anotherSider} tooltipVisible={!isMobile} trigger={null} width={150} collapsible collapsed={rightCollapsed} collapsedWidth={0} style={{height: '100%', background: "#212121", border: "none", zIndex: isMobile ? 2 : 0, display: isMobile ? "flex" : "none", justifyContent: "center", flexDirection: "column", position: "fixed", right: 0  }} breakpoint="md" className={"othersider"}>
                                        <Menu theme={"dark"} mode="inline" _internalDisableMenuItemTitleTooltip={true} defaultSelectedKeys={[null]} selectable={false} style={{background: "#212121", border: "none", height: "100%", overflow: "auto", display: "flex", justifyContent: "center", flexDirection: "column"}} className={"right-menu"}>
                                            <>
                                                <Menu.Item
                                                    key="1"
                                                    // style={isHovered1 ? { ...customMenuItemStyle, ...hoverMenuItemStyle } : customMenuItemStyle}
                                                    className="custom-menu-item"
                                                    icon={<UserOutlined />}
                                                    // onMouseEnter={() => handleMouseEnter(1)}
                                                    // onMouseLeave={() => handleMouseLeave(1)}
                                                    onClick={() => profile()}
                                                >
                                                    Profile
                                                </Menu.Item>
                                                <Menu.Item
                                                    key="2"
                                                    // style={isHovered2 ? { ...customMenuItemStyle, ...hoverMenuItemStyle } : customMenuItemStyle}
                                                    className="custom-menu-item"
                                                    icon={<SettingOutlined />}
                                                    // onMouseEnter={() => handleMouseEnter(2)}
                                                    // onMouseLeave={() => handleMouseLeave(2)}
                                                    onClick={() => settings()}
                                                >
                                                    Settings
                                                </Menu.Item>
                                                <Menu.Item
                                                    key="3"
                                                    // style={isHovered3 ? { ...customMenuItemStyle, ...hoverMenuItemStyle } : customMenuItemStyle}
                                                    className="custom-menu-item"
                                                    icon={<LogoutOutlined />}
                                                    // onMouseEnter={() => handleMouseEnter(3)}
                                                    // onMouseLeave={() => handleMouseLeave(3)}
                                                    onClick={() => logout()}
                                                >
                                                    Logout
                                                </Menu.Item>
                                            </>
                                        </Menu>
                                    </Sider>
                                </Layout>
                            </LayoutBlocker>
                        </div>
                </ConfigProvider>
            </ThemeProvider>
        </div>
    );
}

export default MainLayout;