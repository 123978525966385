import React from 'react';
import Markdown from "markdown-to-jsx";

const ViewUser = ({ user }) => {
    const formatDate = (isoDate) => {
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
        };
        const formattedDate = new Date(isoDate).toISOString().replace('T', ' ').slice(0, 16);
        return formattedDate;
    };

    const commentStyle = {
        borderRadius: '8px',
        padding: '16px',
        marginBottom: '16px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexDirection: 'column',
    };

    const labelStyle = {
        fontWeight: 'bold',
        marginBottom: '4px',
    };

    const dataStyle = {
        marginBottom: '8px',
    };

    // // console.log("User : ", user);

    const renderMarkdown = (content) => {
        return (
            <Markdown
                options={{
                    overrides: {
                        img: ({ alt, src }) => (
                            <img
                                alt={alt}
                                src={src}
                                style={{ maxWidth: '100%', height: 'auto' }}
                            />
                        ),
                    },
                }}
            >
                {content === undefined || content === null ? "" : content}
            </Markdown>
        );
    };

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ marginBottom: '32px' }}>
                    <label style={labelStyle}>ID:</label>
                    <div style={dataStyle}>{user.id}</div>
                </div>

                <div style={{ marginBottom: '32px' }}>
                    <label style={labelStyle}>Username:</label>
                    <div style={dataStyle}>{user.username}</div>
                </div>

                <div style={{ marginBottom: '32px' }}>
                    <label style={labelStyle}>Email:</label>
                    <div style={dataStyle}>{user.email}</div>
                </div>

                <div style={{ marginBottom: '32px' }}>
                    <label style={labelStyle}>Display Name:</label>
                    <div style={dataStyle}>{user.displayName}</div>
                </div>

                <div style={{ marginBottom: '32px' }}>
                    <label style={labelStyle}>Permissions:</label>
                    <div style={dataStyle}>{user.permissions}</div>
                </div>

                <div style={{ marginBottom: '32px' }}>
                    <label style={labelStyle}>Date of Birth:</label>
                    <div>{formatDate(user.dateOfBirth)}</div>
                </div>
            </div>
        </div>
        // <></>
    );
}

export default ViewUser;
