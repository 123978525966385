// Assuming you have a slice for token information
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    username: "",
    permissions: "",
    displayName: "",
}

const tokenSlice = createSlice({
    name: "token",
    initialState,
    reducers:{
        storeUser: (state, action) => {
            Object.assign(state, action.payload);
        },
        logoutUser: (state) => {
            // Reset the state to its initial values
            Object.assign(state, initialState);
        },
    },
})

export default tokenSlice.reducer;

export const { storeUser, logoutUser } = tokenSlice.actions;
