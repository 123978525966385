import React from 'react';
import { Form, Input, Button, ConfigProvider, theme, DatePicker } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone, EyeOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { storeUser } from '../redux/tokenSlice';
import axios from 'axios';
import { config } from '../../config';
import { jwtDecode } from 'jwt-decode';
import { error, success, info, warning } from '../common/toastr';
import moment from 'moment';


const LoginPage = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onFinish = (values) => {
    // Add your login logic here, maybe make an API call to validate credentials
    let token = {};
    axios.post(config.baseUrl + "/user/create", {
      username: values.username.trim().toLowerCase(),
      displayName: values.displayName.trim(),
      email: values.email.trim(),
      dateOfBirth: values.dateOfBirth,
      password: values.password.trim()
    }).then(response => {
      // // console.log("Response: ", response);
      token = response.data;
      if(token.status == 1)
      {
        success("Account created successfully")
        navigate('/')
      }
      else if(token.status == 2)
      {
        warning("Account already exists")
      }
      else if(token.status == 3)
      {
        warning("Username already exists")
      }
      else if(token.status == 4)
      {
        error("Achievement : How did we get here?", "Good News: You found a bug! Bad News: I now have to fix it.")
      }
    }).catch(exception => {
      // console.log(exception);
      error(exception.message)
    });
  };

  return (
    // <ConfigProvider
    //   theme={{
    //     algorithm: theme.darkAlgorithm
    //   }}
    // >
      <div className="login-container">
        <Form
          colon={false}
          form={form}
          name="login-form"
          onFinish={onFinish}
          size={"default"}
          className="login-form"
          requiredMark={false}
          layout='vertical'
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[
              {
                required: true,
                message: 'Please enter your username!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please enter your email!',
              },
              {
                type: 'email',
                message: 'Please enter a valid email address!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Display Name"
            name="displayName"
            rules={[
              {
                required: true,
                message: 'Please enter your display name!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: 'Please enter your password!',
              },
              {
                min: 8,
                message: 'Password must be at least 8 characters long!',
              },
            ]}
          >
            <Input.Password
              // iconRender={visible => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
            />
          </Form.Item>

          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: 'Please enter your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The passwords do not match!'));
                },
              }),
            ]}
          >
            <Input.Password
              // iconRender={visible => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
            />
          </Form.Item>

          <Form.Item
            label="Date of Birth"
            name="dateOfBirth"
            rules={[
              {
                required: true,
                message: 'Please enter your Date of Birth!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  // Get the user's date of birth
                  const dob = new Date(value);
                  // Calculate age
                  const today = new Date();
                  let age = today.getFullYear() - dob.getFullYear();

                  // Adjust age if birthday hasn't occurred yet this year
                  if (today.getMonth() < dob.getMonth() || (today.getMonth() === dob.getMonth() && today.getDate() < dob.getDate())) {
                    age--;
                  }
                  // Check if the user is 13 or older
                  if (age >= 13) {
                    return Promise.resolve();
                  }

                  return Promise.reject('You must be at least 13 years old to register!');
                },
              }),
            ]}
          >
            <DatePicker allowClear={false} placement='bottomRight'></DatePicker>
          </Form.Item>


          <Form.Item>
            <div className={"center-button"}>
              <Button htmlType="submit">
                Register
              </Button>
            </div>
          </Form.Item>

          <Form.Item>
            <div style={{textAlign: "center"}}>
              <small>By creating a user account, you agree to the <Link to={"/legal"}>terms of service</Link>.</small>
            </div>
          </Form.Item>

        </Form>
      </div>
      // </ConfigProvider>
  );
};

export default LoginPage;
