import { useSelector } from 'react-redux';
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import React from "react";

const RouteBlocker = ({ searchString, children }) => {
    const location = useLocation();
    const Error = () => {
        return null
    }
    const redux = useSelector((state) => state);

    const reduxString = redux.token.permissions;

    const navigate = useNavigate();

    const isPermissionPresent = () => {
        if(location.pathname === "/admin"){
            return true
        }
        else{
            return false
        }
    };

    // Render children only if the permission is present
    return isPermissionPresent() ? <Error /> : children;
};

export default RouteBlocker;
