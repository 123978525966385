import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import {Card, ConfigProvider, Pagination, Select, Spin, Tag, theme} from 'antd'; // Import Ant Design Select
import {DoubleLeftOutlined, DoubleRightOutlined, LeftOutlined, LoadingOutlined, RightOutlined} from "@ant-design/icons";
// import 'antd/dist/antd.css'; // Import Ant Design styles
import { config } from '../../config';

// const Pagination = ({ currentPage, totalPages, onPageChange }) => {
//   const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);
//
//   const options = pageNumbers.map((number) => ({
//     value: number,
//     label: String(number),
//   }));
//
//   return (
//       <div className="pagination-container">
//         <div className="pagination">
//           <button
//               onClick={() => onPageChange(1)}
//               disabled={currentPage === 1}
//               style={{ color: currentPage === 1 ? '#555' : 'white' }}
//           >
//             <DoubleLeftOutlined />
//           </button>
//           <button
//               onClick={() => onPageChange(currentPage - 1)}
//               disabled={currentPage === 1}
//               style={{ color: currentPage === 1 ? '#555' : 'white' }}
//           >
//             <LeftOutlined />
//           </button>
//           {/* Replace the Select component */}
//           <Select
//               value={currentPage}
//               options={options}
//               onChange={(newPage) => onPageChange(newPage)}
//               style={{ width: 80, color: '#ffffff' }}
//               dropdownStyle={{ backgroundColor: '#212121', border: "1px solid #666"}}
//           />
//           <button
//               onClick={() => onPageChange(currentPage + 1)}
//               disabled={currentPage === totalPages}
//               style={{ color: currentPage === totalPages ? '#555' : 'white' }}
//           >
//             <RightOutlined />
//           </button>
//           <button
//               onClick={() => onPageChange(totalPages)}
//               disabled={currentPage === totalPages}
//               style={{ color: currentPage === totalPages ? '#555' : 'white' }}
//           >
//             <DoubleRightOutlined />
//           </button>
//         </div>
//       </div>
//   );
// };

const Blog = () => {
  const [spin, setSpin] = useState(true);
  const [blogs, setBlogs] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  const getTagColor = (topic) => {
    switch (topic.toLowerCase()) {
      case 'technology':
        return 'volcano';
      case 'gaming':
        return 'geekblue';
      case 'programming':
        return 'lime';
      case 'updates':
        return 'purple'
      default:
        return 'gold';
    }
  };

  const fetchBlogs = async (newPage) => {
    try {
      setSpin(true)
      const response = await axios.get(`${config.baseUrl}/blog?page=${newPage}&pageSize=10`);
      setBlogs(response.data.content);
      setTotalPages(response.data.totalPages);
      setSpin(false)
    } catch (error) {
      if(error.response && error.response.status == 404)
      {
        navigate("/notfound");
      }
      if(error.code == "ERR_NETWORK" || error.response.status >= 500)
      {
        navigate("/error");
      }
      console.error('Error fetching blogs:', error);
    } finally {
      setIsLoading(false);
      setInitialLoad(false);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const pageParam = parseInt(urlParams.get('page')) || 1;

    if (pageParam !== page) {
      setPage(pageParam);
      fetchBlogs(pageParam); // Fetch blogs immediately if the page parameter is present in the URL
    } else {
      fetchBlogs(page); // Fetch blogs for the current page
    }
  }, [page, navigate]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    navigate(`/blog?page=${newPage}`);
  };

  const formatDate = (isoDate) => {
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    };

    return new Date(isoDate).toLocaleString('en-US', options);
  };

  const handleCardClick = (blogId) => {
    // Redirect to the detailed blog page using the navigate function
    navigate(`/blog/${blogId}`);
  };
  const truncatedText = (text) => {
    return text.length > 100 ? `${text.substring(0, 100)}...` : text;
  };


  return (
      <div>
        <div>
            {blogs.map((blog) => (
                <>
                  {/* Replace Link component with onClick event */}
                  <Link to={"/blog/" + blog.id}>
                    <Card
                        title={blog.title}
                        extra={<Tag color={getTagColor(blog.topic)}>{blog.topic}</Tag>}
                        style={{width: "100%", cursor: "pointer"}}
                        hoverable={true}
                        // onClick={() => handleCardClick(blog.id)}
                        key={blog.id}
                    >
                      {/* Additional content of your Card if needed */}
                      <span style={{ color: "grey", wordWrap: "break-word" }}>{truncatedText(blog.content)}</span>
                      <br /><br />
                      <div style={{textAlign: "right"}}><small>{formatDate(blog.date)}</small></div>
                      {/*<div className="date hide-date-on-mobile">{formatDate(blog.date)}</div>*/}
                    </Card>
                  </Link>
                  <br/>
                </>
            ))}

          {/*<Pagination currentPage={page} totalPages={totalPages} onPageChange={handlePageChange} />*/}
          <Pagination
              current={page}
              total={totalPages * 10} // Assuming 10 blogs per page, adjust accordingly
              pageSize={10} // Adjust based on your page size
              onChange={handlePageChange}
              showSizeChanger={false} // Remove if you want to enable page size changer
              style={{ marginTop: '16px', textAlign: 'center' }}
          />

        </div>
        <Spin spinning={spin} fullscreen={true} indicator={<LoadingOutlined />} size={"large"}/>
      </div>
  );
};

export default Blog;