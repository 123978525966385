// src/config.js
const environments = {
  dev: {
    env: 'dev',
    baseUrl: 'https://dev-api.nogoat.in/api'
  } , 
  prod: {
    env: 'prod',
    baseUrl: 'https://api.nogoat.in/api',
  },
  local: {
    env: 'local',
    baseUrl: 'http://localhost:17000/api', // Change the port accordingly
  },
};

const currentEnvironment = 'prod';

export const config = environments[currentEnvironment];
  
