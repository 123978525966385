import { useSelector } from 'react-redux';
import {Navigate, useNavigate} from "react-router-dom";
import {success, warning} from "./toastr";
import {Button, ConfigProvider, Result, theme} from "antd";
import React from "react";

const LoginBlocker = ({ children }) => {

    const Error = () => {
        return (
            <Navigate to={"/"} />)
    }
    const navigate = useNavigate();

    const isTokenPresent = () => {
        try {
            const token = localStorage.getItem('token'); // Replace 'yourTokenKey' with the actual key
            // console.log(!!token)
            return !!token;
        } catch (error) {
            warning("Localstorage is disabled. Login won't work.")
            return false;
        }
    };

    // Render children only if the permission is present
    return isTokenPresent() ? <Error /> : children;
};

export default LoginBlocker;
