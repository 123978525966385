import React, { useEffect, useState } from 'react';
import {Button, Menu, Dropdown, message, notification, theme, ConfigProvider, Space, Divider} from 'antd';
import {DownOutlined, UserOutlined} from '@ant-design/icons';
import {Link, useNavigate} from 'react-router-dom';
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { success, warning } from '../common/toastr';
import { useDispatch, useSelector } from 'react-redux';
import { jwtDecode } from 'jwt-decode';
import {logoutUser, storeUser} from '../redux/tokenSlice';
import {LoginOutlined} from "@mui/icons-material";

const customMenuStyle = {
  backgroundColor: '#212121',
  border: '1px solid #424242',
};

const customMenuItemStyle = {
  color: '#ffffff', /* Change to match heading color */
};

const hoverMenuItemStyle = {
  backgroundColor: '#1963b3',
  color: '#ffffff',
};

const customButtonStyle = {
  marginRight: '8px',
  // padding: '10px', /* Add padding to match the button styles */
  cursor: 'pointer',
};

const hoverButtonStyle = {
  backgroundColor: '#424242',
};

// Apply these styles as needed in your components
const isTokenAvailable = () => {
  try
  {
    if(localStorage.getItem('token'))
    {
      return true
    }
    else
    {
      return false
    }
  }
  catch(error)
  {
    // console.log(error)
    warning("You have disabled local storage. Login won't work.")
    // localStorage.getItem('token')
  }
}

const LoginButton = ({setMenu, collapsed, otherCollapsed}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    let choice
    try
    {
      choice = localStorage.getItem('token')
    }
    catch (error)
    {
      console.error(error)
      warning("You have disabled local storage. Login won't work.")
    }
    if(choice)
    {
      const decodeToken = jwtDecode(localStorage.getItem("token"))
      let userObject = {
        username: decodeToken.sub,
        permissions: decodeToken.permissions,
        displayName: decodeToken.displayName
      }
      dispatch(storeUser(userObject))
    }
  },[])
  const redux = useSelector((state) => state)
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const navigate = useNavigate();
  const login = () => {
    navigate('/login');
  };

  const CustomMessage = () => (
    <div style={{ backgroundColor: '#000000', borderRadius: '5px', padding: '10px', color: '#ffffff' }}>
      This is a cool message!
    </div>
  );

  const toggleSidebar = () => {
    collapsed(!collapsed);
    otherCollapsed(true);
  };



  const profile = () => {
    navigate('/profile/' + redux.token.username)
  }

  const settings = () => {
    navigate('/settings')
  }


  const logout = () => {
    try
    {
      localStorage.clear();
    }
    catch(error)
    {
      console.error(error)
      warning("You have disabled local storage. Login won't work.")
    }
    navigate('/')
    dispatch(logoutUser())
    success("Logged out successfully")
    // window.location.reload()
  }

  const menuItems = (
      <>
        <Menu.Item
            key="1"
            // style={isHovered1 ? { ...customMenuItemStyle, ...hoverMenuItemStyle } : customMenuItemStyle}
            className="custom-menu-item"
            // onMouseEnter={() => handleMouseEnter(1)}
            // onMouseLeave={() => handleMouseLeave(1)}
            onClick={() => profile()}
        >
          Profile
        </Menu.Item>
        <Menu.Item
            key="2"
            // style={isHovered2 ? { ...customMenuItemStyle, ...hoverMenuItemStyle } : customMenuItemStyle}
            className="custom-menu-item"
            // onMouseEnter={() => handleMouseEnter(2)}
            // onMouseLeave={() => handleMouseLeave(2)}
            onClick={() => settings()}
        >
          Settings
        </Menu.Item>
        <Menu.Item
            key="3"
            // style={isHovered3 ? { ...customMenuItemStyle, ...hoverMenuItemStyle } : customMenuItemStyle}
            className="custom-menu-item"
            // onMouseEnter={() => handleMouseEnter(3)}
            // onMouseLeave={() => handleMouseLeave(3)}
            onClick={() => logout()}
        >
          Logout
        </Menu.Item>
      </>
  )

  const menu = (
    <Menu theme="dark">
      {menuItems}
    </Menu>
  );
  if(setMenu) {
    setMenu(menuItems);
  }

  const token = localStorage.getItem('token'); // Replace 'yourTokenKey' with the actual key
  // console.log("WHAT THE FUK IS DIS", !!token)

  return (
    <div
    >
      <div>
        {isTokenAvailable() ? (
            <>
            <Dropdown.Button overlay={menu} className={"hide-on-mobile"} placement="bottom" trigger={['click']} icon={<DownOutlined/>} onClick={() => {
              navigate("/profile/" + redux.token.username)
            }}>
              {redux.token.displayName}
            </Dropdown.Button>
              {/*<Dropdown overlay={menu} className={"show-on-mobile"} placement="bottom" trigger={['click']} icon={<DownOutlined/>}>*/}
                <div style={{width: "50px", height: "50px"}} className={"show-on-mobile right-sider"} onClick={toggleSidebar}>
                  <UserOutlined style={{marginTop: "18px", marginRight: "18px"}} />
                </div>
              {/*</Dropdown>*/}
            </>
        ) : (
            <>
              <Button
                className="custom-button hide-on-mobile"
                style={customButtonStyle}
                onClick={login}
              >
                Login
              </Button>
              <div style={{ height: "50px", width: "50px"}} className="show-on-mobile" onClick={login}><div style={{marginTop: "13px", marginRight: "18px"}}><LoginOutlined style={{fontWeight: "default"}}/></div></div>
            </>
        )}
      </div>
    </div>
  );
};

export default LoginButton;
