// Import necessary Ant Design components
import {ConfigProvider, Divider, Layout, Menu, Result, Spin, theme} from 'antd';
import {
    CommentOutlined,
    DashboardOutlined,
    DesktopOutlined,
    HomeOutlined,
    MenuFoldOutlined,
    MenuOutlined,
    MenuUnfoldOutlined,
    ReadOutlined,
    CustomerServiceOutlined,
    AimOutlined,
    PoweroffOutlined,
    AreaChartOutlined,
    UserOutlined,
    SettingOutlined,
    LogoutOutlined
} from '@ant-design/icons';
import React, {useEffect, useRef, useState} from "react";
import TopBar from "../common/TopBar";
import LoginButton from "../Login/LoginButton";
import {Navigate, useNavigate} from "react-router-dom";
import Dashboard from "./Dashboard";
import Users from "./Users";
import Comments from "./Comments";
import AppleMusic from "./AppleMusic"
import {success, warning} from "../common/toastr";
import {logoutUser} from "../redux/tokenSlice";
import {useDispatch, useSelector} from "react-redux";

const { Header, Sider, Content } = Layout;

// Your AdminPage component
const AdminPage = () => {
    const dispatch = useDispatch()
    const redux = useSelector((state) => state)
    const [rightCollapsed, setRightCollapsed] = useState(true);
    // State to handle the collapsed state of the sidebar
    const [collapsed, setCollapsed] = useState(true);
    const [selectedMenuItem, setSelectedMenuItem] = useState("1"); // Default to Dashboard
    const [isMobile, setIsMobile] = useState(window.innerWidth < 800); // Set initial value based on screen width
    const siderRef = useRef(null);


    const navigate = useNavigate();

    // Function to handle sidebar collapse
    const profile = () => {
        setCollapsed(true);
        setRightCollapsed(true);
        navigate('/profile/' + redux.token.username)
    }

    const settings = () => {
        setCollapsed(true);
        setRightCollapsed(true);
        navigate('/settings')
    }


    const logout = () => {
        setCollapsed(true);
        setRightCollapsed(true);
        try
        {
            localStorage.clear();
        }
        catch(error)
        {
            console.error(error)
            warning("You have disabled local storage. Login won't work.")
        }
        navigate('/')
        dispatch(logoutUser())
        success("Logged out successfully")
        // window.location.reload()
    }

    const toggleSidebar = () => {
        setCollapsed(!collapsed);
        setRightCollapsed(true);
    };

    const handleMenuClick = (key) => {
        setSelectedMenuItem(key);
        isMobile && setCollapsed(true);
        isMobile && setRightCollapsed(true);
    };

    const onClick = () => {
        navigate("/")
    }

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 800);
            setCollapsed(true);
            setRightCollapsed(true);
        };

        // Attach the event listener
        window.addEventListener("resize", handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            const toggleButton = document.querySelector(".your-custom-button-class"); // Replace with the actual class of your custom button
            const leftSider = document.querySelector(".left-sider")
            const rightSider = document.querySelector(".right-sider")
            const otherSider = document.querySelector(".other-sider")


            // Check if the click occurred outside the Sider and not within the custom button
            if (
                // siderRef.current &&
                // !siderRef.current.contains(event.target) &&
                toggleButton && (!toggleButton || !toggleButton.contains(event.target)) &&
                leftSider && (!leftSider || !leftSider.contains(event.target)) &&
                rightSider && (!rightSider || !rightSider.contains(event.target)) &&
                otherSider && (!otherSider || !otherSider.contains(event.target)) &&
                isMobile
            ) {
                setCollapsed(true); // Collapse the Sider
            }
        };

        // Attach the event listener to the document
        document.addEventListener("click", handleOutsideClick);

        // Clean up the event listener on component unmount
        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, [siderRef, isMobile]);



    return (
        <div>
        <Layout style={{ minHeight: '100vh' }}>
            {/* Collapsible Sidebar */}
            <Sider ref={siderRef} tooltipVisible={!isMobile} trigger={null} width={isMobile ? 150 : 200} collapsible collapsed={collapsed} collapsedWidth={isMobile ? 0 : 80} style={{height: '100%', left: 0, background: "#212121", border: "none", zIndex: isMobile ? 2 : 0, display: isMobile ? "flex" : "flex", justifyContent: "center", flexDirection: "column", position: "fixed"  }} breakpoint="md" className={isMobile ? "left-sider" : "left-sider bordered-sider"}>
            {/*<Sider ref={siderRef} width={collapsed ? 80 : } tooltipVisible={!isMobile} trigger={null} collapsible collapsed={collapsed} style={{height: '100%', position: 'fixed', left: isMobile ? (collapsed ? '-80px' : '0') : '0', background: "#212121", border: "none", zIndex: isMobile ? 2 : 0 }} breakpoint="md">*/}
                <div className="logo" />
                <Menu theme={isMobile ? "dark" : "light"} mode="inline" defaultSelectedKeys={[null]} selectable={false} style={!isMobile ? {background: "#212121", border: "none", height: "calc(100% - 48px)"} : {background: "#212121", border: "none", height: "100%", overflow: "auto", display: "flex", justifyContent: "center", flexDirection: "column"}} className={"left-menu"}>
                    <Menu.Item key="0" icon={<MenuOutlined />} onClick={toggleSidebar} disabled={false} style={{background: "none", display: isMobile ? "none" : null}}>

                    </Menu.Item>
                    <Menu.Item key="1" icon={<AreaChartOutlined />} onClick={() => handleMenuClick("1")}>
                        Dashboard
                    </Menu.Item>
                    <Menu.Item key="2" icon={<DesktopOutlined />} onClick={() => handleMenuClick("2")}>
                        Users
                    </Menu.Item>
                    <Menu.Item key="3" icon={<CommentOutlined />} onClick={() => handleMenuClick("3")}>
                        Comments
                    </Menu.Item>
                    <Menu.Item key="4" icon={<ReadOutlined />} onClick={() => handleMenuClick("4")}>
                        Posts
                    </Menu.Item>
                    <Menu.Item key="5" icon={<HomeOutlined />} onClick={() => handleMenuClick("5")}>
                        Home
                    </Menu.Item>
                    <Menu.Item key="6" icon={<PoweroffOutlined />} onClick={onClick} style={{display: !isMobile ? "none" : null}}>
                        Go Back
                    </Menu.Item>
                </Menu>
                <Menu theme={isMobile ? "dark" : "light"} mode={"inline"} defaultSelectedKeys={[null]} selectable={false} style={{background: "#212121", border: "none"}} className={"left-menu"}>
                    <Menu.Item key="5" icon={<PoweroffOutlined />} onClick={onClick}>
                        Go Back
                    </Menu.Item>
                </Menu>
            </Sider>

            {/* Main Content Area */}
            <Layout className="site-layout" style={{
                marginLeft: !isMobile ? collapsed ? '80px' : '200px' : "0px",
                transition: 'margin-left 0.3s',
                backgroundColor: "#121212",
                padding: isMobile ? '0' : '20px',
                // paddingTop: "20px",
                overflowY: 'auto',
                zIndex: 0,
                position: "relative"
            }}>
                <Content style={{backgroundColor: "#121212"}} className={"content"}>
                    <Spin
                        spinning={!collapsed || !rightCollapsed}
                        fullscreen={true}
                        style={{zIndex: 1, display: isMobile ? "block" : "none"}}
                        indicator={null}
                        onClick={() => {
                            setCollapsed(true)
                            setRightCollapsed(true)
                        }}
                        onTouchStart={() => {
                            setCollapsed(true)
                            setRightCollapsed(true)
                        }}
                    />
                <header
                    style={!isMobile ? {background: "transparent", border: "none", padding: 0, margin: 0, paddingLeft: 10, paddingRight: 20} : null}
                >
                    {/*<h1>NoGoat</h1>*/}
                    {isMobile ?
                        <>
                            <div style={{ textAlign: "left", height: "50px", width: "50px", cursor: "pointer"}} onClick={toggleSidebar} className={"your-custom-button-class"}>
                                {/*<TopBar/>*/}
                                <MenuOutlined className={"your-custom-button-class"} style={{marginTop: "18px", marginLeft: "18px"}}/>
                            </div>
                            <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center',marginBottom: '0px' }}>
                                <img src={process.env.PUBLIC_URL + '/logo512.png'} alt="Logo" style={{ width: "64px", height: "64px", cursor: "pointer" }} onClick={() => {onClick("home")}} className={"logo"}/>
                            </div>
                            <div style={{marginBottom: !isMobile ? "0px" : null,
                                height: "50px",
                                width: "50px",
                                textAlign: "right",
                                cursor: "pointer"
                            }}>
                                <LoginButton
                                    // setMenu={setMenu}
                                    collapsed={setRightCollapsed}
                                    otherCollapsed={setCollapsed}
                                />
                            </div>
                        </>:
                        <>
                            <img src={process.env.PUBLIC_URL + '/logo512.png'}
                                 style={{width: "64px", height: "64px", cursor: "pointer"}}
                                 onClick={() => {
                                     onClick("home")
                                 }}
                                 className={"logo"}
                            />
                            <nav>
                                <LoginButton
                                    // setMenu={setMenu}
                                    collapsed={setRightCollapsed}
                                    otherCollapsed={setCollapsed}
                                />
                            </nav>
                        </>
                    }
                </header>
                <Divider type={"horizontal"} style={{
                    borderTopColor: "#424242",
                    marginBottom: "0px",
                    marginTop: "0px",
                    padding: "0px"
                }}></Divider>

                <div style={{marginTop: 30, marginLeft: isMobile ? 15 : null, marginRight: isMobile ? 15 : null}}>
                {/* Page Content */}
                    {selectedMenuItem === "5" && <AppleMusic/>}
                    {selectedMenuItem === "3" && <Comments/>}
                    {selectedMenuItem === "2" && <Users/>}
                    {selectedMenuItem === "1" && <Dashboard/>}
                    {/* Add more conditions for other components if needed */}
                </div>
                </Content>
                <Sider ref={siderRef} tooltipVisible={!isMobile} trigger={null} width={150} collapsible collapsed={rightCollapsed} collapsedWidth={0} style={{height: '100%', position: 'fixed', right: '0', background: "#212121", border: "none", zIndex: 2, display: isMobile ? "flex" : "none", justifyContent: "center", flexDirection: "column" }} breakpoint="md" className={"other-sider"}>
                    <Menu theme={"dark"} mode="inline" defaultSelectedKeys={[null]} selectable={false} style={{background: "#212121", border: "none", height: "100%", overflow: "auto", display: "flex", justifyContent: "center", flexDirection: "column"}} className={"right-menu"}>
                        <>
                            <Menu.Item
                                key="1"
                                // style={isHovered1 ? { ...customMenuItemStyle, ...hoverMenuItemStyle } : customMenuItemStyle}
                                className="custom-menu-item"
                                icon={<UserOutlined />}
                                // onMouseEnter={() => handleMouseEnter(1)}
                                // onMouseLeave={() => handleMouseLeave(1)}
                                onClick={() => profile()}
                            >
                                Profile
                            </Menu.Item>
                            <Menu.Item
                                key="2"
                                // style={isHovered2 ? { ...customMenuItemStyle, ...hoverMenuItemStyle } : customMenuItemStyle}
                                className="custom-menu-item"
                                icon={<SettingOutlined />}
                                // onMouseEnter={() => handleMouseEnter(2)}
                                // onMouseLeave={() => handleMouseLeave(2)}
                                onClick={() => settings()}
                            >
                                Settings
                            </Menu.Item>
                            <Menu.Item
                                key="3"
                                // style={isHovered3 ? { ...customMenuItemStyle, ...hoverMenuItemStyle } : customMenuItemStyle}
                                className="custom-menu-item"
                                icon={<LogoutOutlined />}
                                // onMouseEnter={() => handleMouseEnter(3)}
                                // onMouseLeave={() => handleMouseLeave(3)}
                                onClick={() => logout()}
                            >
                                Logout
                            </Menu.Item>
                        </>
                    </Menu>
                </Sider>
            </Layout>
        </Layout>
        </div>
    );
};

export default AdminPage;
