import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import MainLayout from "./components/Misc/MainLayout";

function App() {
    return(
        <Router>
            <MainLayout />
        </Router>
    )
}

export default App;