import React, { useEffect, useState } from 'react';
import {Row, Col, Card, ConfigProvider, Skeleton, theme, Dropdown, Form, Input, Tag} from 'antd';
import axios from "axios";
import { config } from "../../config";
import {Link, useNavigate} from "react-router-dom";
import Iframe from 'react-iframe';
import {TextField} from "@mui/material";
import {MoreOutlined} from "@ant-design/icons";
// import {isMobileDevice} from "react-select/dist/declarations/src/utils";

const HomePage = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 800); // Set initial value based on screen width

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 800);
        };

        // Attach the event listener
        window.addEventListener("resize", handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const formatCustomDate = (dateString) => {
        const options = {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        };
        return new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
    };

    const navigate = useNavigate();
    const [latest, setLatest] = useState([]);
    const [latestLoading, setLatestLoading] = useState(true);
    const [latestCommentsLoading, setLatestCommentsLoading] = useState(true);
    const [comments, setComments] = useState([]);
    const [songs, setSongs] = useState([]);
    const [featuredPosts, setFeaturedPosts] = useState([]);

    const getTagColor = (topic) => {
        switch (topic.toLowerCase()) {
            case 'technology':
                return 'volcano';
            case 'gaming':
                return 'geekblue';
            case 'programming':
                return 'lime';
            case 'updates':
                return 'purple'
            default:
                return 'gold';
        }
    };

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await axios.get(`${config.baseUrl}/blog?page=1&pageSize=3`);
                setLatest(response.data.content);
                setLatestLoading(false);
            } catch (error) {
                console.error('Error fetching blogs:', error);
            }
        };

        const fetchComments = async () => {
            try {
                const response = await axios.get(`${config.baseUrl}/comment/home-comments`);
                setComments(response.data.content);
                setLatestCommentsLoading(false);
            } catch (error) {
                console.error('Error fetching comments:', error);
            }
        };

        const fetchSongs = async () => {
            try {
                const response = await axios.get(`${config.baseUrl}/songs`);
                setSongs(response.data);
                // setLatestCommentsLoading(false);
            } catch (error) {
                console.error('Error fetching songs:', error);
            }
        };

        const fetchFeaturedPosts = async () => {
            try {
                const response = await axios.get(`${config.baseUrl}/featured-posts`);
                setFeaturedPosts(response.data);
                // setLatestCommentsLoading(false);
            } catch (error) {
                console.error('Error fetching songs:', error);
            }
        };

        fetchBlogs();
        fetchComments();
        fetchSongs();
        fetchFeaturedPosts();
    }, []);

    const truncatedText = (text) => {
        return text.length > 100 ? `${text.substring(0, 100)}...` : text;
    };

    const onClick = (id) => {
        if (latest[id]) {
            navigate("/blog/" + latest[id].id);
        }
    };

    const renderCard = (index) => (
        <Link to={"/blog/" + (latest[index] !== undefined ? latest[index].id : "1")}>
            <Card
                title={latest[index] ? latest[index].title : <Skeleton paragraph={false} />}
                bordered={true}
                hoverable={true}
                loading={latestLoading}
                extra={latest[index] ? (<Tag color={getTagColor(latest[index].topic)}>{latest[index].topic}</Tag>) : null}
                onClick={() => onClick(index)}
                style={{zIndex: 0}}
                bodyStyle={isMobile ? null : {minHeight: "150px", position: "relative"}}
            >
                <span style={{ color: "grey", wordWrap: "break-word" }}>{latest[index] ? truncatedText(latest[index].content) : null}</span>
                {/*<br /><br />*/}
                <div style={isMobile ? {textAlign: "right", marginTop: "24px"} : {
                    textAlign: "right",
                    bottom: 24,
                    right: 24,
                    left: 24,
                    position: "absolute",
                    marginTop: "24px"
                }}>
                    <small>{latest[index] ? formatCustomDate(latest[index].date) : null}</small>
                </div>
            </Card>
        </Link>
    );

    const renderFeaturedPostCard = (featuredPost) => (
        <>
            <Link to={"/blog/" + (featuredPost !== undefined ? featuredPost.id : "1")}>
                <Card
                    title={featuredPost ? featuredPost.title : <Skeleton paragraph={false} />}
                    bordered={true}
                    hoverable={true}
                    loading={latestLoading}
                    extra={featuredPost ? (<Tag color={getTagColor(featuredPost.topic)}>{featuredPost.topic}</Tag>) : null}
                    // onClick={() => onClick(index)}
                    style={{zIndex: 0}}
                    bodyStyle={isMobile ? null : {minHeight: "150px", position: "relative"}}
                >
                    <span style={{ color: "grey", wordWrap: "break-word" }}>{featuredPost ? truncatedText(featuredPost.content) : null}</span>
                    {/*<br /><br />*/}
                    <div style={isMobile ? {textAlign: "right", marginTop: "24px"} : {
                        textAlign: "right",
                        bottom: 24,
                        right: 24,
                        left: 24,
                        position: "absolute",
                        marginTop: "24px"
                    }}>
                        <small>{featuredPost ? formatCustomDate(featuredPost.date) : null}</small>
                    </div>
                </Card>
            </Link>
            <br/>
        </>
    );

    const RenderCommentsCard = (comment) => {
        return (
            <>
                <Link to={"/blog/" + comment.postId}>
                    <Card
                        key={comment.id}
                        style={{zIndex: 0}}
                        bodyStyle={isMobile ? null : {minHeight: "150px", position: "relative"}}
                        // extra={<Link to={"/blog/" + comment.postId}>Post</Link> }
                        title={
                            <div>
                                <span>{comment.displayName}</span>
                                <h6 style={{
                                    margin: 0,
                                    padding: 0,
                                    color: 'gray'
                                }}><Link to={"/profile/" + comment.userId}>{"@" + comment.userId}</Link></h6>
                            </div>
                        }
                        hoverable={true}
                    >
                        <span style={{color: 'gray', wordWrap: "break-word"}}>{truncatedText(comment.comment)}</span>
                        <div style={isMobile ? {textAlign: "right", marginTop: "24px"} : { textAlign: "right", bottom: 24, right: 24, left: 24, position: "absolute", marginTop: "24px"}}>
                            {(comment.commentedOn === comment.modifiedOn ? null : <>
                                <Tag>Edited</Tag>&nbsp;</>)}<small>{formatCustomDate(comment.modifiedOn)}</small>
                        </div>
                    </Card>
                </Link>
                <br/>
            </>
        )
    };

    console.log("songs: ", songs)

    return (
        // <ConfigProvider theme={{ algorithm: theme.darkAlgorithm }}>
            <div style={{minHeight: "788.8px"}}>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={8}>
                        <Card
                            title="Latest Posts"
                            bordered={false}
                            style={{background: "transparent"}}
                            bodyStyle={{marginLeft: 0, paddingLeft: 0, marginRight: 0, paddingRight: 0}}
                            className={"first-card"}
                        >
                            {renderCard(0)}
                            <br/>
                            {renderCard(1)}
                            <br/>
                            {renderCard(2)}
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={8}>
                        <Card
                            title="Featured Posts"
                            bordered={false}
                            style={{background: "transparent"}}
                            bodyStyle={{marginLeft: 0, paddingLeft: 0, marginRight: 0, paddingRight: 0}}
                            className={"first-card"}
                        >
                            {featuredPosts.length > 0 ? featuredPosts.map(featuredPost => {
                                return renderFeaturedPostCard(featuredPost);
                            }) : <div style={{textAlign:"center"}}><i style={{textAlign: "center"}}>No Featured Posts</i></div>}
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={8}>
                        <Card
                            title="Latest Comments"
                            bordered={false}
                            style={{background: "transparent"}}
                            bodyStyle={{marginLeft: 0, paddingLeft: 0, marginRight: 0, paddingRight: 0}}
                            className={"first-card"}
                        >
                            {comments.length > 0 ? comments.map(comment => {
                                return RenderCommentsCard(comment);
                            }) : <div style={{textAlign:"center"}}><i style={{textAlign: "center"}}>No Comments</i></div>}
                        </Card>
                    </Col>
                </Row>
                <br/>
                <Card
                    style={{
                        width: "100%",
                        margin: "auto",
                        overflow: "hidden",
                        background: "transparent"
                    }}
                    bordered={false}
                    title={"My playlists"}
                    bodyStyle={{marginLeft: 0, marginRight: 0, paddingLeft: 0, paddingRight: 0,}}
                    className={"first-card"}
                >
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <iframe
                            title="Apple Music Playlist"
                            allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write"
                            frameBorder="0"
                            height="450"
                            style={{width: '100%', maxWidth: '660px', overflow: 'hidden', borderRadius: '10px'}}
                            sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
                            src={(songs ? (songs.find(item => item.id == 1) ? songs.find(item => item.id == 1).url : "https://embed.music.apple.com/in/playlist/taylor-swift/pl.u-xlyNE3XIJPYM9yv") : "https://embed.music.apple.com/in/playlist/taylor-swift/pl.u-xlyNE3XIJPYM9yv") + (localStorage.getItem("mode") ? (localStorage.getItem("mode") === "dark" ? "?theme=dark" : "?theme=light") : "?theme=dark")}
                        ></iframe>
                    </div>
                </Card>
                <br/>
                <Card
                    style={{
                        width: "100%",
                        margin: "auto",
                        overflow: "hidden",
                        background: "transparent"
                    }}
                    bordered={false}
                    title={"Song of the week"}
                    bodyStyle={{marginLeft: 0, marginRight: 0, paddingLeft: 0, paddingRight: 0,}}
                    className={"first-card"}
                >
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <iframe
                            title="Apple Music Song"
                            allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write"
                            frameBorder="0"
                            height="187"
                            style={{width: '100%', maxWidth: '660px', overflow: 'hidden', borderRadius: '10px'}}
                            sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
                            // src="https://embed.music.apple.com/in/album/hits-different/1689131527?i=1689132079&theme=dark"
                            src={(songs ? (songs.find(item => item.id == 2) ? songs.find(item => item.id == 2).url : "https://embed.music.apple.com/us/album/one-step-closer/590431776?i=590431778") : "https://embed.music.apple.com/us/album/one-step-closer/590431776?i=590431778") + (localStorage.getItem("mode") ? (localStorage.getItem("mode") === "dark" ? "?theme=dark" : "?theme=light") : "?theme=dark")}
                        ></iframe>
                    </div>
                </Card>
            </div>
        // </ConfigProvider>
);
};

export default HomePage;
