import React from 'react';
import Markdown from "markdown-to-jsx";

const ViewComments = ({ comment }) => {
    const formatDate = (isoDate) => {
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
        };
        const formattedDate = new Date(isoDate).toISOString().replace('T', ' ').slice(0, 16);
        return formattedDate;
    };

    const commentStyle = {
        borderRadius: '8px',
        padding: '16px',
        marginBottom: '16px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexDirection: 'column',
    };

    const labelStyle = {
        fontWeight: 'bold',
        marginBottom: '4px',
    };

    const dataStyle = {
        marginBottom: '8px',
    };

    const renderMarkdown = (content) => {
        return (
            <Markdown
                options={{
                    overrides: {
                        img: ({ alt, src }) => (
                            <img
                                alt={alt}
                                src={src}
                                style={{ maxWidth: '100%', height: 'auto' }}
                            />
                        ),
                    },
                }}
            >
                {content === undefined || content === null ? "" : content}
            </Markdown>
        );
    };

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ marginBottom: '32px' }}>
                    <label style={labelStyle}>ID:</label>
                    <div style={dataStyle}>{comment.id}</div>
                </div>

                <div style={{ marginBottom: '32px' }}>
                    <label style={labelStyle}>Post ID:</label>
                    <div style={dataStyle}>{comment.postId}</div>
                </div>

                <div style={{ marginBottom: '32px' }}>
                    <label style={labelStyle}>Comment:</label>
                    <div style={dataStyle}>{renderMarkdown(comment.comment)}</div>
                </div>

                <div style={{ marginBottom: '32px' }}>
                    <label style={labelStyle}>User ID:</label>
                    <div style={dataStyle}>{comment.userId}</div>
                </div>

                <div style={{ marginBottom: '32px' }}>
                    <label style={labelStyle}>Commented On:</label>
                    <div style={dataStyle}>{formatDate(comment.commentedOn)}</div>
                </div>

                <div style={{ marginBottom: '32px' }}>
                    <label style={labelStyle}>Modified On:</label>
                    <div>{formatDate(comment.modifiedOn)}</div>
                </div>
            </div>
        </div>
    );
}

export default ViewComments;
