import React, { useState, useEffect } from 'react';
import { Space, Table, Drawer, Button, Modal } from 'antd';
import axios from 'axios';
import { config } from "../../config";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import ViewComments from "./ViewComments";

const Comments = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 800);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [modalVisible, setModalVisible] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [selectedComment, setSelectedComment] = useState(null);
    const actionClass = "table-action";
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
        position: ["bottomCenter"]
    });

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 60,
        },
        {
            title: 'Post ID',
            dataIndex: 'postId',
            key: 'postId',
            width: 81,
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment',
            width: 450
        },
        {
            title: 'User ID',
            dataIndex: 'userId',
            key: 'userId',
            width: 140
        },
        {
            title: 'Commented On',
            dataIndex: 'commentedOn',
            key: 'commentedOn',
            width: 140,
            render: (text, record) => formatDate(record.commentedOn),
        },
        {
            title: 'Modified On',
            dataIndex: 'modifiedOn',
            key: 'modifiedOn',
            width: 140,
            render: (text, record) => formatDate(record.modifiedOn),
        },
        {
            title: 'Action',
            key: 'action',
            // fixed: isMobile ? null : "right",
            render: (text, record) => (
                <Space>
                    <EyeOutlined
                        className={actionClass}
                        onClick={() => handleView(record)}
                        style={{fontSize: "16px", textAlign: "center", paddingRight: 8}}
                    />
                    <EditOutlined className={actionClass} onClick={() => handleEdit(record)} style={{fontSize: "16px", textAlign: "center", paddingRight: 8}}/>
                    <DeleteOutlined className={actionClass} onClick={() => handleDelete(record)} style={{fontSize: "16px", textAlign: "center"}}/>
                </Space>
            ),
            width: 110
        },
    ];

    const formatDate = (isoDate) => {
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
        };
        const formattedDate = new Date(isoDate).toISOString().replace('T', ' ').slice(0, 16);
        return formattedDate;
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 800);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(config.baseUrl + '/admin/comments', {
                    params: {
                        page: pagination.current,
                        pageSize: pagination.pageSize,
                    },
                    headers: {
                        "token": localStorage.getItem("token")
                    }
                });
                setData(response.data.content);
                setPagination({
                    ...pagination,
                    total: response.data.totalPages * pagination.pageSize,
                });
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [pagination.current, pagination.pageSize]);

    const handleTableChange = (pagination) => {
        setPagination(pagination);
    };

    const handleView = (record) => {
        setSelectedComment(record);
        setModalVisible(true);
    };

    const handleEdit = (record) => {
        setSelectedComment(record);
        setEditModalVisible(true);
    };

    const handleDelete = (record) => {
        setSelectedComment(record);
        setDeleteModalVisible(true);
    };

    const handleDeleteConfirmed = (record) => {
        // Handle delete confirmation logic here
        setDeleteModalVisible(false);
    };

    const viewDrawer = (
        <Drawer
            title="View Comment"
            visible={modalVisible}
            onClose={() => setModalVisible(false)}
            footer={null}
            mask={true}
            size={"default"}
            width={isMobile ? "100%" : null}
            extra={
                <Space>
                    <Button onClick={() => {
                        setModalVisible(false)
                        setEditModalVisible(true)
                    }}>Edit</Button>
                    <Button onClick={() => {
                        setModalVisible(false)
                        setDeleteModalVisible(true)
                    }}>Delete</Button>
                </Space>
            }
        >
            {selectedComment && (
                <ViewComments comment={selectedComment} />
            )}
        </Drawer>
    );


    const editDrawer = (
        <Drawer
            title="Edit Comment"
            visible={editModalVisible}
            onClose={() => setEditModalVisible(false)}
            footer={null}
            mask={true}
            size={"default"}
            width={isMobile ? "100%" : null}
        >
            {selectedComment && (
                <div>
                    {/* Your edit comment form or details here */}
                    <Button type="primary" onClick={() => setEditModalVisible(false)}>
                        Save Changes
                    </Button>
                </div>
            )}
        </Drawer>
    );

    const deleteModal = (
        <Modal
            title="Delete Comment"
            open={deleteModalVisible}
            onOk={() => {}}
            centered={true}
            onCancel={() => setDeleteModalVisible(false)}
            // footer={null}
            mask={true}
            okButtonProps={{
                type: "default"
            }}
        >
            {selectedComment && (
                // <div>
                    /* Display confirmation message or additional details */
                    // <Button type="danger" onClick={() => handleDeleteConfirmed(selectedComment)}>
                        <>Are you sure you want to delete?</>
                    // </Button>
                // </div>
            )}
        </Modal>
    );

    return (
        <div>
            <Table
                rowSelection={{
                    type: "checkbox",
                    // fixed: "left"
                }}
                className={"customTable"}
                columns={columns}
                dataSource={data}
                loading={isLoading}
                pagination={pagination}
                onChange={handleTableChange}
                scroll={{ y: "55vh" }}
                style={{ width: "100%", zIndex: 0 }}
            />
            {viewDrawer}
            {editDrawer}
            {deleteModal}
        </div>
    );
};

export default Comments;
