import React, {useEffect, useState} from 'react';
import {Card, Switch, Input, Button, Space, Drawer, Modal, Form, message} from 'antd';
import PermissionChecker from "./permissionChecker";
import permissionChecker from "./permissionChecker";
import axios from "axios";
import {config} from "../../config";
import {error, success} from "./toastr";
import {jwtDecode} from "jwt-decode";
import {storeUser} from "../redux/tokenSlice";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

const SettingsPage = ({renderArg}) => {
    const navigate = useNavigate();

    const [darkMode, setDarkMode] = useState(localStorage.getItem('mode') ? (localStorage.getItem('mode') === "dark") : true);

    const [user, setUser] = useState(false);

    const [password, setPassword] = useState(false);

    const dispatch = useDispatch();

    const [deleteModalVisible, setDeleteModalVisible] = useState(false);

    const [deleteAccount, setDeleteAccount] = useState(false)

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 800); // Set initial value based on screen width

    const [reRender, setRerender] = useState("1");
    const [showUsernameField, setShowUsernameField] = useState(false);

    const [newPasswordField, setNewPasswordField] = useState(false);
    const [confirmNewPasswordField, setConfirmNewPasswordField] = useState(false);

    const [form] = Form.useForm();
    const [newForm] = Form.useForm();
    const [deleteForm] = Form.useForm();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 800);
        };

        // Attach the event listener
        window.addEventListener("resize", handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const onFinish = (values) => {
        // Handle password submission here if needed
        if(!showUsernameField)
        {
            setShowUsernameField(true);
        }
        else if(showUsernameField)
        {
            const object = {
                password: form.getFieldValue("password"),
                displayName: form.getFieldValue("displayname")
            }
            axios.post(config.baseUrl + "/user/change-displayname", object,{
                headers: {
                    token: localStorage.getItem("token")
                }
            }).then(response => {
                // // console.log("Response", response.data.success)
                if(response.data.status === "success")
                {
                    setShowUsernameField(false)
                    setUser(false)
                    form.resetFields();
                    localStorage.setItem("token", response.data.token);
                    const decodeToken = jwtDecode(response.data.token)
                    // // console.log("Decode Token: ", decodeToken)
                    let userObject = {
                        username: decodeToken.sub,
                        permissions: decodeToken.permissions,
                        displayName: decodeToken.displayName
                    }
                    dispatch(storeUser(userObject))
                    success("Changed Display Name successfully")
                }
                else {
                    error("Incorrect Password")
                    setShowUsernameField(false)
                    setUser(false)
                    form.resetFields();
                }
            }).catch(exception => {
                console.error(exception)
                error(exception)
            })
        }
    };

    const onPasswordFinish = (values) => {
        // Handle password submission here if needed
        if(!newPasswordField && !confirmNewPasswordField)
        {
            setNewPasswordField(true);
            setConfirmNewPasswordField(false)
        }
        else if(newPasswordField && !confirmNewPasswordField)
        {
            setNewPasswordField(false);
            setConfirmNewPasswordField(true)
        }
        else if(!newPasswordField && confirmNewPasswordField)
        {
            if(newForm.getFieldValue("newpassword") === newForm.getFieldValue("confirmnewpassword"))
            {
                const object = {
                    password: newForm.getFieldValue("password"),
                    newPassword: newForm.getFieldValue("newpassword")
                }
                axios.post(config.baseUrl + "/user/change-password", object,{
                    headers: {
                        token: localStorage.getItem("token")
                    }
                }).then(response => {
                    // // console.log("Response", response.data.success)
                    if(response.data.status === "success")
                    {
                        setNewPasswordField(false)
                        setConfirmNewPasswordField(false)
                        newForm.resetFields();
                        setPassword(false)
                        success("Changed Password successfully")
                    }
                    else {
                        error("Incorrect Password")
                        setNewPasswordField(false)
                        setConfirmNewPasswordField(false)
                        newForm.resetFields();
                        setPassword(false)
                    }
                }).catch(exception => {
                    console.error(exception)
                    error(exception)
                })
            }
        }
    };

    const onDeleteFinish = () => {
        // success("Deleted Successfully")
        // deleteForm.resetFields();
        // setDeleteAccount(false)
        const object = {
            password: deleteForm.getFieldValue("password"),
            // displayName: form.getFieldValue("displayname")
        }
        axios.post(config.baseUrl + "/user/delete-account", object,{
            headers: {
                token: localStorage.getItem("token")
            }
        }).then(response => {
            // // console.log("Response", response.data.success)
            if(response.data.status === "success")
            {
                setDeleteAccount(false)
                deleteForm.resetFields();
                localStorage.clear();
                success("Account deleted successfully")
                navigate("/")
            }
            else {
                error("Incorrect Password")
                setDeleteAccount(false)
                deleteForm.resetFields();
            }
        }).catch(exception => {
            console.error(exception)
            error(exception)
        })
    }

    const handleDarkModeChange = (checked) => {
        setDarkMode(checked);
        localStorage.setItem('mode', checked ? "dark" : "light");
        renderArg((prevArg) => {return prevArg + 1});
    };
    return (
        // <Card title="Settings" style={{ width: 400, margin: 'auto', marginTop: 20 }}>
        <div style={{maxWidth: "800px", marginLeft: "auto", marginRight: "auto"}}>
            <div style={{marginBottom: 16}}>
                <Space align="baseline" style={{width: '100%', justifyContent: 'space-between'}}>
                    <h4>Change Display name</h4>
                    <Button type="link" style={{paddingRight: 0}} onClick={() => {
                        setUser(true)
                    }}>Change</Button>
                </Space>
            </div>

            <div style={{marginBottom: 16}}>
                <Space align="baseline" style={{width: '100%', justifyContent: 'space-between'}}>
                    <h4>Change Password</h4>
                    <Space>
                        {/*<Input.Password style={{ width: 200 }} />*/}
                        <Button type="link" style={{paddingRight: 0}} onClick={() => {
                            setPassword(true)
                        }}>Change</Button>
                    </Space>
                </Space>
            </div>

            <div>
                <Space align="baseline" style={{width: '100%', justifyContent: 'space-between', marginBottom: "16px"}}>
                    <h4>Dark Mode</h4>
                    <Switch checked={darkMode} onChange={handleDarkModeChange}/>
                </Space>
            </div>
            <div style={{marginBottom: 16}}>
                <Space align="baseline" style={{width: '100%', justifyContent: 'space-between'}}>
                    <h4>Delete Account</h4>
                    <Space>
                        {/*<Input.Password style={{ width: 200 }} />*/}
                        <Button type="link" danger={true} style={{paddingRight: 0}} onClick={() => {
                            setDeleteModalVisible(true)
                        }}>Delete</Button>
                    </Space>
                </Space>
            </div>
            <Modal
                // title={<span>&nbsp;</span>}
                centered={!isMobile}
                open={user}
                onCancel={() => {
                    setShowUsernameField(false)
                    form.resetFields();
                    setUser(false)
                }}
                // footer={null}
                footer={[
                    <Button htmlType="submit" onClick={() => form.submit()}>
                        {(showUsernameField) ? "Submit" : "Next"}
                    </Button>
                ]}
                mask={true}
                size={"default"}
                // width={"100%"}
            >
                <div>
                    <Form
                        size={"default"}
                        form={form}
                        name="change-form"
                        onFinish={onFinish}
                        requiredMark={false}
                        layout={"vertical"}
                        initialValues={{remember: true}}
                    >
                        {!showUsernameField && <Form.Item
                            name="password"
                            label="Password"
                            rules={[{required: true, message: 'Please enter your password!'}]}
                        >
                            <Input.Password/>
                        </Form.Item>}

                        {showUsernameField && (
                            <Form.Item
                                name="displayname"
                                label="Display Name"
                                rules={[{required: true, message: 'Please enter your username!'}]}
                            >
                                <Input/>
                            </Form.Item>
                        )}

                        {/*<Form.Item>*/}

                        {/*</Form.Item>*/}
                    </Form>
                </div>
            </Modal>
            <Modal
                // title={<span>&nbsp;</span>}
                centered={!isMobile}
                open={password}
                onCancel={() => {
                    setNewPasswordField(false)
                    setConfirmNewPasswordField(false)
                    newForm.resetFields();
                    setPassword(false)
                }}
                // footer={null}
                footer={[
                    <Button htmlType="submit" onClick={() => newForm.submit()}>
                        {(!newPasswordField && confirmNewPasswordField) ? "Submit" : "Next"}
                    </Button>
                ]}
                mask={true}
                size={"default"}
                // width={"100%"}
            >
                <div>
                    <Form
                        size={"default"}
                        form={newForm}
                        name="change-password-form"
                        onFinish={onPasswordFinish}
                        requiredMark={false}
                        layout={"vertical"}
                        initialValues={{remember: true}}
                    >
                        {!newPasswordField && !confirmNewPasswordField && <Form.Item
                            name="password"
                            label="Password"
                            rules={[{required: true, message: 'Please enter your password!'}]}
                        >
                            <Input.Password/>
                        </Form.Item>}

                        {newPasswordField && !confirmNewPasswordField && (
                            <Form.Item
                                name="newpassword"
                                label="New Password"
                                rules={[{required: true, message: 'Please enter your new password!'}]}
                            >
                                <Input.Password/>
                            </Form.Item>
                        )}

                        {!newPasswordField && confirmNewPasswordField && (
                            <Form.Item
                                name="confirmnewpassword"
                                label="Confirm New Password"
                                rules={[{required: true, message: 'Please enter your new password!'}]}
                            >
                                <Input.Password/>
                            </Form.Item>
                        )}

                        {/*<Form.Item>*/}

                        {/*</Form.Item>*/}
                    </Form>
                </div>
            </Modal>
            <Modal
                // title={<span>&nbsp;</span>}
                centered={!isMobile}
                open={deleteAccount}
                onCancel={() => {
                    deleteForm.resetFields();
                    setDeleteAccount(false)
                }}
                // footer={null}
                footer={[
                    <Button htmlType="submit" onClick={() => deleteForm.submit()} danger={true}>
                        Delete
                    </Button>
                ]}
                mask={true}
                size={"default"}
                // width={"100%"}
            >
                <div>
                    <Form
                        size={"default"}
                        form={deleteForm}
                        name="delete-account-form"
                        onFinish={onDeleteFinish}
                        requiredMark={false}
                        layout={"vertical"}
                        initialValues={{remember: true}}
                    >
                        {!newPasswordField && !confirmNewPasswordField && <Form.Item
                            name="password"
                            label="Password"
                            rules={[{required: true, message: 'Please enter your password!'}]}
                        >
                            <Input.Password/>
                        </Form.Item>}
                    </Form>
                </div>
            </Modal>
            <Modal
                title="Delete Account"
                open={deleteModalVisible}
                onOk={() => {

                    setDeleteModalVisible(false)
                    setDeleteAccount(true)
                }}
                centered={true}
                onCancel={() => setDeleteModalVisible(false)}
                // footer={null}
                mask={true}
                okButtonProps={{
                    type: "default",
                    danger: true,
                }}
            >
                <>Are you sure you want to delete your account?</>
            </Modal>
        </div>
        // {/*</Card>*/}
    );
};

function debounce(callback, delay) {
    let timeoutId;
    return function (...args) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            callback.apply(this, args);
        }, delay);
    };
}

export default SettingsPage;
