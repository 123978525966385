import React from 'react';
import { Form, Input, Button, ConfigProvider, theme } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone, EyeOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { storeUser } from '../redux/tokenSlice';
import axios from 'axios';
import { config } from '../../config';
import { jwtDecode } from 'jwt-decode';
import { error, success, info, warning } from '../common/toastr';

const LoginPage = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onFinish = (values) => {
    // Add your login logic here, maybe make an API call to validate credentials
    let token = {};
    axios.post(config.baseUrl + "/user/login", {
      username: values.username.trim(),
      password: values.password.trim()
    }).then(response => {
      token = response.data;
      if (token?.statusCode === 1) {
        try {
          localStorage.setItem("token", token.token);
          let userObject = {}
          success("Logged in successfully");
          const decodeToken = jwtDecode(token.token)
          // // console.log("Decode Token: ", decodeToken)
          userObject = {
            username: decodeToken.sub,
            permissions: decodeToken.permissions,
            displayName: decodeToken.displayName
          }
          dispatch(storeUser(userObject))
          // // console.log("User Object : ", userObject)
        } catch (error) {
          console.error(error)
          warning("You have disabled local storage. Login won't work.")
        }
        navigate("/");
      } else if (token?.statusCode === 2) {
        error("Incorrect Password");
      } else if (token?.statusCode === 3) {
        info("The specified account doesn't exist");
      }
      // // console.log("Token : ", token);
    }).catch(exception => {
      // // console.log(exception);
      error(exception.message)
    });
  };

  return (
    <div
    >
      <div className="login-container">
        <Form
          colon={false}
          form={form}
          name="login-form"
          onFinish={onFinish}
          size={"default"}
          className="login-form"
          requiredMark={false}
          layout='vertical'
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[
              {
                required: true,
                message: 'Please enter your username!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: 'Please enter your password!',
              },
            ]}
          >
            <Input.Password
              iconRender={visible => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
            />
          </Form.Item>

          <Form.Item>
            <div className={"center-button"}>
              <Button htmlType="submit">
                Login
              </Button>
            </div>
          </Form.Item>

          <Form.Item>
            <div style={{ textAlign: "center" }}>
              <label>Not a user? <Link to={"/register"}>Register</Link></label>
            </div>
          </Form.Item>

        </Form>
      </div>
    </div>
  );
};

export default LoginPage;
