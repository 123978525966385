import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import {Link, useNavigate, useParams} from 'react-router-dom';
import { config } from '../../config';
import ReactMarkdown from 'react-markdown';
import {
    Skeleton,
    theme,
    ConfigProvider,
    Card,
    Form,
    Tag,
    Spin,
    Menu,
    Dropdown,
    Modal,
    Input,
    Avatar,
    Button
} from "antd";
import Markdown from "markdown-to-jsx";
import TextArea from "antd/es/input/TextArea";
import InfiniteScroll from 'react-infinite-scroll-component';
import { LoadingOutlined, MoreOutlined } from "@ant-design/icons";
import { error, success } from "../common/toastr";

const BlogPost = () => {
    const redux = useSelector((state) => state);
    const reduxString = redux.token.permissions
    const userId = redux.token.username
    const navigate = useNavigate();
    const { id } = useParams();

    const [isMobile, setIsMobile] = useState(window.innerWidth < 800); // Set initial value based on screen width
    const [editedCommentId, setEditedCommentId] = useState(null);
    const [editedComment, setEditedComment] = useState('');
    const [isEditing, setIsEditing] = useState(false);

    const [blog, setBlog] = useState(null);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [form] = Form.useForm();
    const [commentForm] = Form.useForm();
    const [editCommentForm] = Form.useForm(); // Step 3: Create a new form for editing comments
    const [comments, setComments] = useState([]);
    const [commentsLoading, setCommentsLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [menuVisible, setMenuVisible] = useState(null);

    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [deleteCommentId, setDeleteCommentId] = useState(null);

    const onCancel = () => {
        editCommentForm.resetFields(); // Step 4: Reset the form when canceling
        setEditedCommentId(null);
        setIsEditing(false);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 800);
        };

        // Attach the event listener
        window.addEventListener("resize", handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // Add this useEffect hook to update editedComment when editedCommentId changes
    useEffect(() => {
        if (editedCommentId !== null) {
            const commentToEdit = comments.find(comment => comment.id === editedCommentId);
            setEditedComment(commentToEdit.comment);
        }
    }, [editedCommentId, comments]);


    const fetchComments = async (page) => {
        try {
            setCommentsLoading(true);
            const response = await axios.get(`${config.baseUrl}/comment/${id}?page=${page}&pageSize=10`);
            const newComments = response.data.content;

            if (page === 1) {
                setComments(newComments);
            } else {
                setComments(prevComments => [...prevComments, ...newComments]);
            }

            setHasMore(response.data.page < response.data.totalPages);
        } catch (error) {
            console.error('Error fetching comments:', error);
        } finally {
            setCommentsLoading(false);
        }
    };

    const updateScreenWidth = () => {
        setScreenWidth(window.innerWidth);
    };

    useEffect(() => {
        // console.log('Fetching blog post...');
        const fetchBlogData = () => {
            axios.get(`${config.baseUrl}/blog/${id}`).then((response) => {
                setBlog(response.data);
            }).catch((error) => {
                if(error.response && error.response.status == 404)
                {
                    navigate("/notfound");
                }
                if(error.code == "ERR_NETWORK" || error.response.status >= 500)
                {
                    navigate("/error");
                }
                console.error('Error fetching blog post:', error);
            })
        };

        if (id) {
            fetchBlogData();
        }
    }, [id]);

    useEffect(() => {
        // console.log('Fetching comments...');
        if (id) {
            fetchComments(1);
        }
    }, [id]);



    useEffect(() => {
        const handleResize = () => {
            updateScreenWidth();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            // Cleanup the event listener when the component is unmounted
            window.removeEventListener('resize', handleResize);
            updateScreenWidth(); // Initialize screenWidth
        };
    }, []);

    const formatCustomDate = (dateString) => {
        const options = {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        };
        return new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
    };

    const renderMarkdown = (content) => {
        return (
            <Markdown
                options={{
                    overrides: {
                        img: ({ alt, src }) => (
                            <img
                                alt={alt}
                                src={src}
                                style={{ maxWidth: '100%', maxHeight: "400px" }}
                            />
                        ),
                    },
                }}
            >
                {content === undefined || content === null ? "" : content}
            </Markdown>
        );
    };

    const postComment = (commentText) => {
        if (commentText) {
            axios.post(config.baseUrl + '/comment', {
                postId: id,
                comment: commentText,
            }, {
                headers: {
                    'token': localStorage.getItem("token"),
                },
            })
                .then(response => {
                    success('Comment posted successfully');
                    fetchComments(1);
                })
                .catch(exception => {
                    error('Error posting comment:');
                });
        }
    };

    const showDeleteModal = (commentId) => {
        setDeleteCommentId(commentId);
        setDeleteModalVisible(true);
    };

    const handleDeleteOk = () => {
        // console.log(`Deleting comment with ID ${deleteCommentId}`);

        // Perform the Axios delete call
        axios.delete(`${config.baseUrl}/comment/${deleteCommentId}`, {
            headers: {
                'token': localStorage.getItem("token"),
            },
        })
            .then(response => {
                // Handle success if needed
                // console.log('Comment deleted successfully');
                success('Comment deleted successfully');
                fetchComments(1); // Assuming you want to fetch updated comments after deletion
            })
            .catch(error => {
                // Handle error if needed
                console.error('Error deleting comment:', error);
                error('Error deleting comment');
            })
            .finally(() => {
                setDeleteModalVisible(false); // Close the modal regardless of success or failure
            });
    };


    const renderCommentMenu = (commentId) => (
        <Menu onClick={(e) => handleMenuClick(e, commentId)}>
            <Menu.Item key="edit" onClick={() => startEditing(commentId)}>Edit</Menu.Item>
            <Menu.Item key="delete" onClick={() => showDeleteModal(commentId)}>Delete</Menu.Item>
        </Menu>
    );

    const handleDeleteCancel = () => {
        setDeleteModalVisible(false);
    };

    const getTagColor = (topic) => {
        switch (topic.toLowerCase()) {
            case 'technology':
                return 'volcano';
            case 'gaming':
                return 'geekblue';
            case 'programming':
                return 'lime';
            default:
                return 'gold';
        }
    };

    const fetchMoreData = () => {
        const nextPage = Math.floor(comments.length / 10) + 1;
        fetchComments(nextPage);
    };

    const handleMenuClick = (e, commentId) => {
        // console.log(`Clicked on menu item ${e.key} for comment ${commentId}`);
        if (e.key === 'edit') {
            startEditing(commentId);
        } else {
            setMenuVisible(false);
        }
    };

    const handleKeyDown = (e) => {
        // console.log(e.key)
        if (e.key === 'Escape') {
            // Trigger your function here
            onCancel();
            // console.log('Escape key pressed!');
        }
    };

    // console.log("Comments : ", comments);

    const startEditing = (commentId) => {
        setEditedCommentId(commentId);
        const commentToEdit = comments.find(comment => comment.id === commentId);
        setEditedComment(commentToEdit.comment);
        setIsEditing(true);
        setMenuVisible(false);

        // Set the initial value for the form field
        editCommentForm.setFieldsValue({
            editedComment: commentToEdit.comment,
        });
    };


    const handleEditComment = (commentId) => {
        const updatedComment = editCommentForm.getFieldValue('editedComment');
        axios.put(`${config.baseUrl}/comment/${commentId}`, {
            comment: updatedComment,
        }, {
            headers: {
                'token': localStorage.getItem("token"),
            },
        })
            .then(response => {
                success('Comment updated successfully');
                editCommentForm.resetFields();
                setEditedCommentId(null);
                setIsEditing(false);
                fetchComments(1);
            })
            .catch(exception => {
                error('Error updating comment:');
            });
    };

    return (
        <div
        >
            {blog ? (
                <Card
                    style={{
                        width: "100%",
                        margin: "auto",
                        overflow: "hidden",
                        background: "transparent",  // Dark background color
                        color: "#ffffff",      // Light text color
                        borderRadius: "10px",  // Slightly rounded corners
                        boxShadow: isMobile ? null : "0 4px 8px rgba(0, 0, 0, 0.1)",  // Subtle shadow
                    }}
                    bordered={false}
                    title={[
                        <h2 style={{ overflow: "visible", overflowWrap: "break-word", whiteSpace: "normal", color: "#3ca8ff" }} className={"blog-title"}>{blog.title}</h2>,
                        <h5 style={{ color: "#a0a0a0" }}>{screenWidth <= 800 ? formatCustomDate(blog.date) : ''}</h5>,
                    ]}
                    extra={screenWidth > 800 ? formatCustomDate(blog.date) : null}
                    bodyStyle={isMobile ? {marginLeft : 0, marginRight: 0} : null}
                    className={"first-card"}
                >
                    <Tag color={getTagColor(blog.topic)}>{blog.topic}</Tag>
                    <br />
                    <br />
                    <div style={{ width: "100%", maxWidth: "750px", color: "#cccccc" }}>
                        <p style={{width: "100%", wordWrap: "break-word"}}>{renderMarkdown(blog.content)}</p>
                    </div>
                </Card>

            ) : (
                <Skeleton active />
            )}
            <br />
            <Card
                style={{
                    width: "100%",
                    margin: "auto",
                    overflow: "hidden",
                    background: "transparent"
                }}
                bordered={false}
                bodyStyle={screenWidth <= 800 ? {paddingLeft: 0, paddingRight: 0, paddingBottom: 0} : null}
                title="Comments"
                className={"first-card"}
            >
                {reduxString ? (
                    <Card
                        style={{
                            width: "100%",
                            margin: "auto"
                        }}
                        actions={[
                            <div onClick={() => {
                                const commentText = form.getFieldValue('comment');
                                postComment(commentText);
                            }}>Post Comment</div>
                        ]}
                        bodyStyle={{ padding: 0 }}
                    >
                        <Form form={form}>
                            <Form.Item
                                name={'comment'}
                                style={{ padding: 0, margin: 0 }}
                            >
                                <Input.TextArea
                                    name="comment"
                                    style={{
                                        height: "167px",
                                        resize: "none",
                                        border: "none",
                                        borderRadius: "8px",
                                        boxShadow: "none"
                                    }}
                                    maxLength={250}
                                    placeholder={"Add a comment"}
                                />
                            </Form.Item>
                        </Form>
                    </Card>
                ) : (
                    <>
                        <br />
                        <div style={{margin: "auto", textAlign: "center"}}>
                            <label style={{ fontStyle: "italic" }}>Please <a onClick={() => navigate("/login")}>login</a> to post a comment. </label>
                        </div>
                        <br />
                        <br />
                    </>
                )}
                <br />
                <InfiniteScroll
                    dataLength={comments.length}
                    next={fetchMoreData}
                    hasMore={hasMore && !commentsLoading}
                    loader={<h4>Loading...</h4>}
                >
                    {comments.map(comment => (
                        <>
                            <Card
                              key={comment.id}
                              style={comment.id === editedCommentId ? {margin: 0, padding: 0} : { marginBottom: '16px' }}
                              actions={comment.id === editedCommentId ? [
                                <div onClick={() => onCancel()}>Cancel</div>,
                                <div onClick={() => handleEditComment(comment.id)}>Save</div>
                              ] : null}
                            >
                                <Card.Meta
                                    // avatar={<Avatar>{comment.displayName.charAt(0)}</Avatar>}
                                    title={
                                        <div>
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center'
                                            }}>
                                                <span>{comment.displayName}</span>
                                                {comment.userId === userId && (
                                                    <Dropdown overlay={renderCommentMenu(comment.id)}
                                                              trigger={['click']}>
                                                        <MoreOutlined style={{cursor: 'pointer'}}/>
                                                    </Dropdown>
                                                )}
                                            </div>
                                            <h6 style={{
                                                margin: 0,
                                                padding: 0,
                                                color: 'gray'
                                            }}><Link to={"/profile/" + comment.userId}>{"@" + comment.userId}</Link></h6>
                                        </div>
                                    }
                                    description={comment.id === editedCommentId ? (
                                        <Form form={editCommentForm} style={{margin: 0, padding: 0}}>
                                            <Form.Item name="editedComment" style={{margin: 0, padding: 0}}>
                                                <Input.TextArea
                                                    style={{
                                                        resize: "none",
                                                        // border: "1px",
                                                        boxShadow: "none",
                                                        height: "167px"
                                                    }}
                                                    onKeyDown={handleKeyDown}
                                                    maxLength={250}
                                                    value={editedComment}
                                                    // autoSize={{ minRows: 2, maxRows: 6 }}
                                                />
                                            </Form.Item>
                                        </Form>
                                    ) : (
                                        <p style={{ color: 'white', wordWrap: "break-word" }}>{renderMarkdown(comment.comment)}</p>
                                    )}
                                />
                                {comment.id === editedCommentId ? null :
                                <div style={{ marginTop: '16px',  textAlign: "right"}}>
                                    { (comment.commentedOn === comment.modifiedOn ? null : <><Tag>Edited</Tag>&nbsp;</>)}<small>{formatCustomDate(comment.modifiedOn)}</small>
                                </div>}
                            </Card>
                            <br/>
                        </>
                    ))}

                    {commentsLoading && comments.length > 0 && (
                        <div style={{ textAlign: 'center', margin: '10px' }}>
                            <LoadingOutlined />
                        </div>
                    )}
                </InfiniteScroll>
            </Card>
            <Modal
                title="Delete Comment"
                visible={deleteModalVisible}
                onOk={handleDeleteOk}
                centered={true}
                onCancel={handleDeleteCancel}
                okButtonProps={{
                    type: "default"
                }}
            >
                <p>Are you sure you want to delete this comment?</p>
            </Modal>
        </div>
    );
};

export default BlogPost;
