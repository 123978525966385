import { useSelector } from 'react-redux';

const PermissionChecker = (searchString) => {
    const redux = useSelector((state) => state);

    const reduxString = redux.token.permissions;

    const isPermissionPresent = () => {
        // console.log(reduxString.includes(searchString))
        return reduxString.includes(searchString);
    };

    // No JSX rendering, just return the function
    return isPermissionPresent();
};

export default PermissionChecker;
