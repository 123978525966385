// src/components/Home.js
import React from 'react';
import {Collapse} from "antd";
import Markdown from "markdown-to-jsx";

const Legal = () => {
  const privacy = `*Last Updated: Febuary 29, 2024*

  <h3>Information Collection:</h3>
  We collect personal information, including your email address and birthdate. The email is obtained for verification purposes when leaving comments, and the birthdate may be collected solely to comply with age-related legal requirements. We abstain from the utilization of cookies or engaging in any form of tracking activities.

  <h3>Use of Email:</h3>
  The email address supplied is exclusively employed for verification of the registered account. There shall be no dissemination for promotional purposes or any form of marketing communication.

  <h3>Comments and Public Interaction:</h3>
  Your designated name and comments may be visibly public during your engagement with nogoat.in. We implore users to exercise discretion in sharing personal information within this public domain.

  <h3>Security Measures:</h3>
  Reasonable measures are undertaken to secure the confidentiality of your email information. It is imperative to acknowledge that no method of transmission over the internet or electronic storage is entirely impervious.

  <h3>Third-Party Links:</h3>
  nogoat.in refrains from the use of third-party cookies or tracking technologies. We explicitly disclaim responsibility for the privacy practices or content of external sites linked within nogoat.in.

  <h3>Children's Privacy:</h3>
  nogoat.in is not designed for individuals under the age of 13, and we do not knowingly collect personal information from this demographic.

  <h3>Amendments to Privacy Policy:</h3>
  This Privacy Policy may be periodically updated. The date of the latest revision shall be prominently indicated at the top. Changes will be communicated to users through a prominent banner displayed at the top of the screen. By continuing to use the website after such modifications, users agree to be bound by the revised Privacy Policy.

  <h3>Contact Information:</h3>
  For inquiries regarding this Privacy Policy or the management of your information, please direct your communication to [NoGoat](https://github.com/nogoat).

  <h3>Apple Music Embed:</h3>
  nogoat.in embeds Apple Music content for an enhanced user experience. Please be aware that interacting with embedded content may result in data collection by Apple. We encourage users to review Apple's Privacy Policy for more information on how they handle user data.

  <h3>Local Storage:</h3>
  nogoat.in utilizes the localStorage feature to store user settings and login tokens locally on your device. Please be aware that while user settings are stored locally, login tokens are transmitted securely to our servers for authentication purposes. This facilitates a seamless and personalized experience during your interactions with the website. It's important to note that the information stored in localStorage is limited to your device and does not include sensitive personal data beyond the necessary authentication token.`

  const tos = `*Last Updated: Febuary 29, 2024*

  These Terms of Service ("Terms") outline the rules and regulations for using this website.
  
  By accessing nogoat.in, you agree to be bound by these Terms. If you disagree with any part of these terms, please do not use nogoat.in.
  
  <h3>Intellectual Property:</h3>
  The content on nogoat.in, including text, images, and other materials, is owned by NoGoat unless stated otherwise. You agree not to reproduce or distribute any content without proper authorization.
  
  <h3>User Conduct:</h3>
  While engaging with nogoat.in, you agree to behave responsibly and respect the rights of others. nogoat.in is not a platform for hate speech, harassment, or any unlawful activities.
  
  <h3>Comments and Contributions:</h3>
  Users are encouraged to engage through comments and contributions. However, NoGoat reserves the right to moderate and remove any content that violates these Terms.
  
  <h3>Privacy Policy:</h3>
  Your use of nogoat.in is subject to our Privacy Policy, which governs the collection, use, and disclosure of personal information. By continuing to use this website, you explicitly acknowledge and agree to the terms outlined in our Privacy Policy.
  
  <h3>Data Retention:</h3>
  User data, limited to birthdate and email, will be retained for the entire duration of the user's account existence. This data is exclusively utilized for account verification purposes. In the event of an account deletion initiated by the user, all associated data will be promptly and permanently deleted from our records.
  
  <h3>Changes to Terms:</h3>
  nogoat.in reserves the right to update these Terms of Service periodically. The date of the latest revision shall be prominently indicated at the top. Changes will be communicated to users through a prominent banner displayed at the top of the screen. By continuing to use the website after such modifications, users agree to be bound by the revised Terms.
  
  <h3>Limitation of Liability:</h3>
  nogoat.in and its content are provided "as is." NoGoat will not be liable for any damages or losses resulting from the use of nogoat.in.
  
  
  If you have any questions or concerns regarding these Terms, please contact [NoGoat](https://github.com/nogoat).`
  const credits = (
      <>
        <a href="https://storyset.com/web">Web illustrations by Storyset</a>
        <br/>
        <br/>
        <a href="https://storyset.com/worker">Worker illustrations by Storyset</a>
        <br/>
        <br/>
        <a href="https://storyset.com/internet">Internet illustrations by Storyset</a>
      </>);

  const items = [
    {
      key: '1',
      label: 'Terms of Service',
      children: <div style={{margin: "16px"}}><Markdown>{tos}</Markdown></div>,
    },
    {
      key: '2',
      label: 'Privacy Policy',
      children: <div style={{margin: "16px"}}><Markdown>{privacy}</Markdown></div>,
    },
    {
      key: '3',
      label: 'Credits',
      children: <div style={{margin: "16px"}}>{credits}</div>,
    },
  ];

  return (
      <div style={{margin: "auto", maxWidth: "900px"}}>
        <Collapse items={items} defaultActiveKey={['1']} />
      </div>
  );
};

export default Legal;
