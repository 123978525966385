import {
    AimOutlined,
    BookOutlined,
    DashboardOutlined,
    DesktopOutlined,
    HomeOutlined,
    MailOutlined,
    MenuOutlined,
    ReadOutlined,
    MoonOutlined,
    SunOutlined
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import {Menu, Dropdown, theme, ConfigProvider} from "antd";
import {useNavigate} from "react-router-dom";
import {GamepadOutlined, PostAddOutlined} from "@mui/icons-material";
import permissionChecker from "./permissionChecker";
import {useSelector} from "react-redux";

const TopBar = ({renderArg}) => {
    const redux = useSelector((state) => state)
    const navigate = useNavigate();
    const items = [
        {
            label: 'Home',
            key: 'home',
            icon: <HomeOutlined />,
        },
        {
            label: 'Blog',
            key: 'blog',
            icon: <ReadOutlined />
        },
        // {
        //     label: 'Game',
        //     key: 'game',
        //     icon: <AimOutlined />
        // },
        {
            label: 'Post',
            key: 'post',
            icon: <PostAddOutlined />,
            hidden: !permissionChecker("blogs/post")
        },
        {
            label: 'Admin',
            key: 'admin',
            icon: <DesktopOutlined />,
            hidden: !permissionChecker("admin")
        },
        {
            label: localStorage.getItem('mode') ? (localStorage.getItem('mode') === "light" ? 'Dark Mode' : 'Light Mode') : 'Light Mode',
            key: localStorage.getItem('mode') ? (localStorage.getItem('mode') === "light" ? 'darkmode' : 'lightmode') : 'lightmode',
            icon: localStorage.getItem('mode') ? (localStorage.getItem('mode') === "light" ? <MoonOutlined /> : <SunOutlined />) : <SunOutlined />,
            hidden: localStorage.getItem("token")
        }
    ];

    const [current, setCurrent] = useState('home');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 800);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const onClick = (e) => {
        // console.log(e);
        if(e.key == "home")
        {
            navigate("/")
        }
        else if (e.key == "darkmode")
        {
            localStorage.setItem("mode", "dark");
            renderArg((prevArg) => {return prevArg + 1});
        }
        else if (e.key == "lightmode")
        {
            localStorage.setItem("mode", "light");
            renderArg((prevArg) => {return prevArg + 1});
        }
        else
        {
            navigate("/" + e.key)
        }
    };

    const menu = (
        <Menu onClick={onClick} theme={'dark'} selectedKeys={['disabled']}>
            {items.map(item => (
                !item.hidden && (
                    <Menu.Item key={item.key} icon={item.icon}>
                        {item.label}
                    </Menu.Item>
                )
            ))}
        </Menu>
    );
    // console.log(items)
    return isMobile ? (
        <div
        >
        <Dropdown overlay={menu} trigger={['click']} style={{border: "10px solid #666", borderRadius: "8px"}} placement={"bottom"}>
            {/*<Menu className={"ant-menu-horizontal"} mode="horizontal" theme={'dark'} overflowedIndicator={<MenuOutlined />} style={{ backgroundColor: 'transparent' }} />*/}
            <MenuOutlined></MenuOutlined>
        </Dropdown>
        </div>
    ) : (
        <Menu onClick={onClick} className={"ant-menu-horizontal"} selectedKeys={['disabled']} mode="horizontal" overflowedIndicator={<MenuOutlined />} style={{ backgroundColor: 'transparent', borderBottom: "none", justifyContent: 'flex-end' }}>
            {items.map(item => (
                !item.hidden && (
                    <Menu.Item key={item.key} icon={item.icon}>
                        {item.label}
                    </Menu.Item>
                )
            ))}
        </Menu>
    );
};

export default TopBar;
