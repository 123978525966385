import { notification } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined,InfoCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

const success = (message, description) => {
    notification.success({
        message: message,
        description: description,
        icon: <CheckCircleOutlined style={{ color: '#52c41a' }} />,
    });
}

const info = (message, description) => {
    notification.info({
        message: message,
        description: description,
        icon: <InfoCircleOutlined style={{ color: '#1890ff' }} />,
    });
}

const error = (message, description) => {
    notification.error({
        message: message,
        description: description,
        icon: <CloseCircleOutlined style={{ color: '#f5222d' }} />,
    });
}

const warning = (message, description) => {
    notification.warning({
        message: message,
        description: description,
        icon: <ExclamationCircleOutlined style={{ color: '#faad14' }} />,
    });
}

export { success, info, error, warning };
