
import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Card, Statistic } from 'antd';
import { LineChart, Line, XAxis, CartesianGrid, Tooltip } from 'recharts';

const Dashboard = () => {
  const [chartWidth, setChartWidth] = useState(0);
  const chartRef = useRef(null);

  useEffect(() => {
    let resizeObserver;
    const debounceResize = debounce(() => {
      if (chartRef.current) {
        setChartWidth(chartRef.current.clientWidth);
      }
    }, 25);

    if (typeof ResizeObserver !== 'undefined') {
      resizeObserver = new ResizeObserver(debounceResize);
      resizeObserver.observe(chartRef.current);
    } else {
      window.addEventListener('resize', debounceResize);
    }

    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      } else {
        window.removeEventListener('resize', debounceResize);
      }
    };
  }, []);
  let viewsData = [
    { name: 'Jan', 'Post 1': 150, 'Post 2': 180, 'Post 3': 220, 'Post 4': 130, 'Post 5': 195 },
    { name: 'Feb', 'Post 1': 270, 'Post 2': 220, 'Post 3': 260, 'Post 4': 160, 'Post 5': 215 },
    { name: 'Mar', 'Post 1': 380, 'Post 2': 250, 'Post 3': 310, 'Post 4': 190, 'Post 5': 235 },
    { name: 'Apr', 'Post 1': 340, 'Post 2': 280, 'Post 3': 350, 'Post 4': 220, 'Post 5': 255 },
    { name: 'May', 'Post 1': 500, 'Post 2': 310, 'Post 3': 390, 'Post 4': 250, 'Post 5': 275 },
    { name: 'Jun', 'Post 1': 610, 'Post 2': 340, 'Post 3': 430, 'Post 4': 280, 'Post 5': 295 },
    { name: 'Jul', 'Post 1': 720, 'Post 2': 370, 'Post 3': 470, 'Post 4': 300, 'Post 5': 315 },
    { name: 'Aug', 'Post 1': 680, 'Post 2': 400, 'Post 3': 510, 'Post 4': 340, 'Post 5': 335 },
    { name: 'Sep', 'Post 1': 840, 'Post 2': 430, 'Post 3': 550, 'Post 4': 370, 'Post 5': 355 },
    { name: 'Oct', 'Post 1': 950, 'Post 2': 460, 'Post 3': 590, 'Post 4': 400, 'Post 5': 375 },
    { name: 'Nov', 'Post 1': 1010, 'Post 2': 490, 'Post 3': 630, 'Post 4': 430, 'Post 5': 395 },
    { name: 'Dec', 'Post 1': 1070, 'Post 2': 520, 'Post 3': 670, 'Post 4': 460, 'Post 5': 415 }
];

let commentsData = [
    { name: 'Jan', 'Post 1': 60, 'Post 2': 35, 'Post 3': 25, 'Post 4': 15, 'Post 5': 20 },
    { name: 'Feb', 'Post 1': 75, 'Post 2': 45, 'Post 3': 45, 'Post 4': 25, 'Post 5': 30 },
    { name: 'Mar', 'Post 1': 85, 'Post 2': 55, 'Post 3': 65, 'Post 4': 35, 'Post 5': 40 },
    { name: 'Apr', 'Post 1': 95, 'Post 2': 65, 'Post 3': 85, 'Post 4': 45, 'Post 5': 50 },
    { name: 'May', 'Post 1': 105, 'Post 2': 75, 'Post 3': 105, 'Post 4': 55, 'Post 5': 60 },
    { name: 'Jun', 'Post 1': 115, 'Post 2': 85, 'Post 3': 125, 'Post 4': 65, 'Post 5': 70 },
    { name: 'Jul', 'Post 1': 125, 'Post 2': 95, 'Post 3': 145, 'Post 4': 75, 'Post 5': 80 },
    { name: 'Aug', 'Post 1': 135, 'Post 2': 105, 'Post 3': 165, 'Post 4': 85, 'Post 5': 90 },
    { name: 'Sep', 'Post 1': 145, 'Post 2': 115, 'Post 3': 185, 'Post 4': 95, 'Post 5': 95 },
    { name: 'Oct', 'Post 1': 155, 'Post 2': 125, 'Post 3': 205, 'Post 4': 105, 'Post 5': 110 },
    { name: 'Nov', 'Post 1': 165, 'Post 2': 135, 'Post 3': 225, 'Post 4': 115, 'Post 5': 120 },
    { name: 'Dec', 'Post 1': 175, 'Post 2': 145, 'Post 3': 245, 'Post 4': 125, 'Post 5': 125 }
];

const getRandomColorV2 = (i) => {
  switch (i) {
    case 1:
      return "#008000"; // green
    case 2:
      return "#FFD700"; // gold
    case 3:
      return "#FF69B4"; // hot pink
    case 4:
      return "#1E90FF"; // sky blue
    case 5:
      return "#8A2BE2"; // blue violet
    case 6:
      return "#FFA07A"; // light salmon
    case 7:
      return "#32CD32"; // lime green
    case 8:
      return "#20B2AA"; // light sea green
    case 9:
      return "#FF5733"; // orangish
    case 10:
      return "#4682B4"; // steel blue
    case 11:
      return "#6A5ACD"; // slate blue
    case 12:
      return "#FF6347"; // tomato
    case 13:
      return "#8B4513"; // saddle brown
    case 14:
      return "#2F4F4F"; // dark slate gray
    case 15:
      return "#D3D3D3"; // light gray
    case 16:
      return "#696969"; // dim gray
    case 17:
      return "#000080"; // navy
    case 18:
      return "#800000"; // maroon
    case 19:
      return "#808000"; // olive
    case 20:
      return "#C0C0C0"; // silver
    default:
      return "No color mapped for this number";
  }
}


const getRandomColor = (i) => {
  switch (i) {
    case 1:
      return "#FF5733"; // orangish
    case 2:
      return "#1E90FF"; // sky blue
    case 3:
      return "#228B22"; // forest green
    case 4:
      return "#FFD700"; // gold
    case 5:
      return "#8A2BE2"; // blue violet
    case 6:
      return "#FFA07A"; // light salmon
    case 7:
      return "#20B2AA"; // light sea green
    case 8:
      return "#FF69B4"; // hot pink
    case 9:
      return "#32CD32"; // lime green
    case 10:
      return "#FF6347"; // tomato
    case 11:
      return "#4682B4"; // steel blue
    case 12:
      return "#6A5ACD"; // slate blue
    case 13:
      return "#8B4513"; // saddle brown
    case 14:
      return "#2F4F4F"; // dark slate gray
    case 15:
      return "#D3D3D3"; // light gray
    case 16:
      return "#696969"; // dim gray
    case 17:
      return "#000080"; // navy
    case 18:
      return "#800000"; // maroon
    case 19:
      return "#808000"; // olive
    case 20:
      return "#C0C0C0"; // silver
    default:
      return "No color mapped for this number";
  }
}



// function addVariability(data) {
//     for (let i = 0; i < data.length; i++) {
//         for (let key in data[i]) {
//             // Skip the 'name' property
//             if (key !== 'name') {
//                 // Introduce variability by adding a random percentage to the original value
//                 let variability = Math.random() * 0.2 + 0.9; // Random multiplier between 0.9 and 1.1
//                 data[i][key] = Math.round(data[i][key] * variability);
//             }
//         }
//     }
// }
//
// // Apply variability to both viewsData and commentsData
// addVariability(viewsData);
// addVariability(commentsData);

  // Sample data for post views and comments
  const postViewsData = [
    { postId: 1, data: [
        { name: 'Jan', views: 100 },
        { name: 'Feb', views: 200 },
        { name: 'Mar', views: 300 },
        { name: 'Apr', views: 250 },
        { name: 'May', views: 400 },
        { name: 'Jun', views: 500 },
        { name: 'Jul', views: 600 },
        { name: 'Aug', views: 550 },
        { name: 'Sep', views: 700 },
        { name: 'Oct', views: 800 },
        { name: 'Nov', views: 850 },
        { name: 'Dec', views: 900 },
      ]
    },
    { postId: 2, data: [
        { name: 'Jan', views: 150 },
        { name: 'Feb', views: 180 },
        { name: 'Mar', views: 200 },
        { name: 'Apr', views: 220 },
        { name: 'May', views: 240 },
        { name: 'Jun', views: 260 },
        { name: 'Jul', views: 280 },
        { name: 'Aug', views: 300 },
        { name: 'Sep', views: 320 },
        { name: 'Oct', views: 340 },
        { name: 'Nov', views: 360 },
        { name: 'Dec', views: 380 },
      ]
    },
    { postId: 3, data: [
        { name: 'Jan', views: 200 },
        { name: 'Feb', views: 220 },
        { name: 'Mar', views: 250 },
        { name: 'Apr', views: 270 },
        { name: 'May', views: 290 },
        { name: 'Jun', views: 310 },
        { name: 'Jul', views: 330 },
        { name: 'Aug', views: 350 },
        { name: 'Sep', views: 370 },
        { name: 'Oct', views: 390 },
        { name: 'Nov', views: 410 },
        { name: 'Dec', views: 430 },
      ]
    },
    { postId: 4, data: [
        { name: 'Jan', views: 120 },
        { name: 'Feb', views: 140 },
        { name: 'Mar', views: 160 },
        { name: 'Apr', views: 180 },
        { name: 'May', views: 200 },
        { name: 'Jun', views: 220 },
        { name: 'Jul', views: 240 },
        { name: 'Aug', views: 260 },
        { name: 'Sep', views: 280 },
        { name: 'Oct', views: 300 },
        { name: 'Nov', views: 320 },
        { name: 'Dec', views: 340 },
      ]
    },
    { postId: 5, data: [
        { name: 'Jan', views: 180 },
        { name: 'Feb', views: 190 },
        { name: 'Mar', views: 200 },
        { name: 'Apr', views: 210 },
        { name: 'May', views: 220 },
        { name: 'Jun', views: 230 },
        { name: 'Jul', views: 240 },
        { name: 'Aug', views: 250 },
        { name: 'Sep', views: 260 },
        { name: 'Oct', views: 270 },
        { name: 'Nov', views: 280 },
        { name: 'Dec', views: 290 },
      ]
    },
  ];
  const commentsPerPostData = [
    { postId: 1, data: [
        { name: 'Jan', comments: 50 },
        { name: 'Feb', comments: 70 },
        { name: 'Mar', comments: 80 },
        { name: 'Apr', comments: 90 },
        { name: 'May', comments: 100 },
        { name: 'Jun', comments: 110 },
        { name: 'Jul', comments: 120 },
        { name: 'Aug', comments: 130 },
        { name: 'Sep', comments: 140 },
        { name: 'Oct', comments: 150 },
        { name: 'Nov', comments: 160 },
        { name: 'Dec', comments: 170 },
      ]
    },
    { postId: 2, data: [
        { name: 'Jan', comments: 30 },
        { name: 'Feb', comments: 40 },
        { name: 'Mar', comments: 50 },
        { name: 'Apr', comments: 60 },
        { name: 'May', comments: 70 },
        { name: 'Jun', comments: 80 },
        { name: 'Jul', comments: 90 },
        { name: 'Aug', comments: 100 },
        { name: 'Sep', comments: 110 },
        { name: 'Oct', comments: 120 },
        { name: 'Nov', comments: 130 },
        { name: 'Dec', comments: 140 },
      ]
    },
    { postId: 3, data: [
        { name: 'Jan', comments: 20 },
        { name: 'Feb', comments: 40 },
        { name: 'Mar', comments: 60 },
        { name: 'Apr', comments: 80 },
        { name: 'May', comments: 100 },
        { name: 'Jun', comments: 120 },
        { name: 'Jul', comments: 140 },
        { name: 'Aug', comments: 160 },
        { name: 'Sep', comments: 180 },
        { name: 'Oct', comments: 200 },
        { name: 'Nov', comments: 220 },
        { name: 'Dec', comments: 240 },
      ]
    },
    { postId: 4, data: [
        { name: 'Jan', comments: 10 },
        { name: 'Feb', comments: 20 },
        { name: 'Mar', comments: 30 },
        { name: 'Apr', comments: 40 },
        { name: 'May', comments: 50 },
        { name: 'Jun', comments: 60 },
        { name: 'Jul', comments: 70 },
        { name: 'Aug', comments: 80 },
        { name: 'Sep', comments: 90 },
        { name: 'Oct', comments: 100 },
        { name: 'Nov', comments: 110 },
        { name: 'Dec', comments: 120 },
      ]
    },
    { postId: 5, data: [
        { name: 'Jan', comments: 15 },
        { name: 'Feb', comments: 25 },
        { name: 'Mar', comments: 35 },
        { name: 'Apr', comments: 45 },
        { name: 'May', comments: 55 },
        { name: 'Jun', comments: 65 },
        { name: 'Jul', comments: 75 },
        { name: 'Aug', comments: 85 },
        { name: 'Sep', comments: 95 },
        { name: 'Oct', comments: 105 },
        { name: 'Nov', comments: 115 },
        { name: 'Dec', comments: 125 },
      ]
    },
  ];

  // Extract unique months from data
  const uniqueMonths = Array.from(new Set(postViewsData.flatMap(({ data }) => data.map(({ name }) => name))));

  // const getRandomColor = () => {
  //   return '#' + Math.floor(Math.random()*16777215).toString(16);
  // };

  return (
    <div style={{ padding: '24px' }}>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={8}>
          <Card>
            <Statistic
              title="Total Views"
              value={postViewsData.reduce((acc, post) => acc + post.data.reduce((acc, curr) => acc + curr.views, 0), 0)}
            />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Card>
            <Statistic
              title="Total Posts"
              value={postViewsData.length}
            />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Card>
            <Statistic
              title="Total Comments"
              value={commentsPerPostData.reduce((acc, post) => acc + post.data.reduce((acc, curr) => acc + curr.comments, 0), 0)}
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: '24px' }}>
        <Col xs={24} sm={24} md={12}>
          <Card title="Post Views">
            <div className="chart-container" ref={chartRef}>
                <LineChart width={chartWidth} height={300} data={viewsData} isAnimationActive={false}>
                    {/* <CartesianGrid strokeDasharray="3 3" /> */}
                    <XAxis dataKey="name" interval="preserveStartEnd"/>
                    <Tooltip contentStyle={{ backgroundColor: '#333', border: 'none' }} />
                    {[...Array(20)].map((_, index) => (
                        <Line key={`Post ${index + 1}`} type="monotone" dataKey={`Post ${index + 1}`} stroke={getRandomColor(index + 1)} isAnimationActive={false} />
                    ))}
                </LineChart>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Card title="Comments Per Post">
            <div className="chart-container" ref={chartRef}>
                <LineChart width={chartWidth} height={300} data={commentsData} isAnimationActive={false}>
                    {/* <CartesianGrid strokeDasharray="3 3" /> */}
                    <XAxis dataKey="name" interval="preserveStartEnd"/>
                    <Tooltip contentStyle={{ backgroundColor: '#333', border: 'none' }} />
                    {[...Array(20)].map((_, index) => (
                        <Line key={`Post ${index + 1}`} type="monotone" dataKey={`Post ${index + 1}`} stroke={getRandomColorV2(index + 1)} isAnimationActive={false} />
                    ))}
                </LineChart>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

// Debounce function to delay execution of callback
function debounce(callback, delay) {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      callback.apply(this, args);
    }, delay);
  };
}

export default Dashboard;
