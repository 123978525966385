import {useDispatch, useSelector} from 'react-redux';
import {Navigate, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {jwtDecode} from "jwt-decode";
import {storeUser} from "../redux/tokenSlice";



const DecodedToken = () => {
    const dispatch = useDispatch();
    if(localStorage.getItem("token")) {
        const decodeToken = jwtDecode(localStorage.getItem("token"))
        let userObject = {
            username: decodeToken.sub,
            permissions: decodeToken.permissions,
            displayName: decodeToken.displayName
        }
        dispatch(storeUser(userObject))
    }

    // // console.log("Redux String : ", reduxString)
    //
    // // console.log("Redux String : ", reduxString != 0)
}

const RouteBlocker = ({ searchString, children }) => {
    const Unauthorized = () => {
        return (
            <Navigate to={"/unauthorized "}/>)
    }

    const Forbidden = () => {
        return (
            <Navigate to={"/forbidden "}/>)
    }

    const [permission, setPermission] = useState("");
    const navigate = useNavigate();
    DecodedToken();


    const isPermissionPresent = () => {
        // if(reduxString == 0)
        // {
        // }
        if(reduxString != 0){
            return reduxString.includes(searchString);
        }
        else{
            return false
        }
    };

    useEffect(() => {
        if(reduxString != 0)
        {
            setPermission(reduxString);
        }
    }, []);

    const redux = useSelector((state) => state);
    const reduxString = redux.token.permissions;


    // Render children only if the permission is present
    if(permission.length != 0)
    {
        return isPermissionPresent() ? children : <Forbidden />;
    }

    if(!localStorage.getItem("token"))
    {
        return <Unauthorized />
    }
};

export default RouteBlocker;
