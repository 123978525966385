import React, { useEffect, useState } from 'react';
import {Card, Avatar, Pagination, Empty, ConfigProvider, theme, Tabs, Spin, Tag, Result} from 'antd';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { config } from '../../config';
import {Link, useNavigate, useParams} from "react-router-dom";
import {LoadingOutlined} from "@ant-design/icons";

const { TabPane } = Tabs;

const CurrentProfile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 800); // Set initial value based on screen width
    const [comments, setComments] = useState([]);
    const [pagination, setPagination] = useState({});
    const [spin, setSpin] = useState(true);
    const [exists, setExists] = useState(true)
    const redux = useSelector((state) => state);
    const navigate = useNavigate();
    const params = useParams();
    const userId = params.username;

    const formatDate = (isoDate) => {
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
        };
        const formattedDate = new Date(isoDate).toISOString().replace('T', ' ').slice(0, 16);
        return formattedDate;
    };

    const NotFound = () => {
        return (
            // <ConfigProvider theme={{ algorithm: theme.darkAlgorithm }}>
                <Result
                    // status="404"
                    icon={<img src={process.env.PUBLIC_URL + '/icons/404.svg'} style={{maxHeight: 500, maxWidth: 500}}/>}
                    title="404"
                    subTitle="The specified user does not exist."
                />
        )
            // </ConfigProvider>)
    };

    useEffect(() => {
        const fetchComments = async () => {
            try {
                setSpin(true)
                const response = await axios.get(config.baseUrl + `/comment/user/${userId}`);
                setComments(response.data.content);
                setPagination({
                    current: response.data.page,
                    pageSize: response.data.pageSize,
                    total: response.data.totalPages * response.data.pageSize,
                });
                setExists(response.data.exists);
                setSpin(false);
            } catch (error) {
                console.error('Error fetching comments:', error);
            }
        };

        fetchComments();
    }, [userId]);

    const handlePaginationChange = async (page, pageSize) => {
        try {
            const response = await axios.get(
                config.baseUrl + `/comment/user/${userId}?page=${page}&pageSize=${pageSize}`
            );
            setComments(response.data.content);
            setPagination({
                current: response.data.page,
                pageSize: response.data.pageSize,
                total: response.data.totalPages * response.data.pageSize,
            });
        } catch (error) {
            console.error('Error fetching comments:', error);
        }
    };

    // console.log("Comments : ", comments)

    return (
        // <ConfigProvider theme={{ algorithm: theme.darkAlgorithm }}>
        <>
            {exists ? <div style={{ maxWidth: '800px', margin: 'auto' }}>
                <Tabs defaultActiveKey="1" tabBarStyle={{ marginBottom: '16px' }}>
                    <TabPane tab="Comments" key="1">
                        {/*<Card title="Your Comments" style={{ borderRadius: '8px' }}>*/}
                            {comments.length > 0 ? (
                                comments.map((comment) => (
                                    <Link to={"/blog/" + comment.postId}>
                                        <Card key={comment.id} style={{ marginBottom: '16px' }} hoverable={true}>
                                            <Card.Meta
                                                // avatar={<Avatar>{comment.displayName.charAt(0)}</Avatar>}
                                                title={comment.displayName}
                                                description={comment.comment}
                                            />
                                            <div style={{ marginTop: '8px', textAlign: 'right' }}>
                                                { (comment.commentedOn === comment.modifiedOn ? null : <><Tag>Edited</Tag>&nbsp;</>)}<small>{formatDate(comment.modifiedOn)}</small>
                                            </div>
                                        </Card>
                                    </Link>
                                ))
                            ) : (
                                <Empty description="No comments yet." />
                            )}
                            <Pagination
                                current={pagination.current}
                                pageSize={pagination.pageSize}
                                total={pagination.total}
                                onChange={handlePaginationChange}
                                style={{ marginTop: '20px', textAlign: 'center', cursor: 'pointer' }}
                                showSizeChanger={false}
                                showQuickJumper={false}
                                // itemRender={(current, type, originalElement) => {
                                //     if (type === 'prev' || type === 'next') {
                                //         return <div style={{ margin: '0 8px' }}>{originalElement}</div>;
                                //     }
                                //     return originalElement;
                                // }}
                            />
                        {/*</Card>*/}
                    </TabPane>
                    {/* Add more TabPane for other profile aspects if needed */}
                </Tabs>
            </div> : <NotFound />}
            <Spin spinning={spin} fullscreen={true} indicator={<LoadingOutlined />} size={"large"}/>
    </>
        // </ConfigProvider>
    );
};

export default CurrentProfile;
