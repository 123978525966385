import {useDispatch, useSelector} from 'react-redux';
import {Navigate, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {jwtDecode} from "jwt-decode";
import {storeUser} from "../redux/tokenSlice";

const LoginChecker = ({ children }) => {
    const Unauthorized = () => {
        return (
            <Navigate to={"/unauthorized "}/>)
    }

    const [permission, setPermission] = useState("");
    const navigate = useNavigate();
    useEffect(() => {
        if(reduxString != 0)
        {
            setPermission(reduxString);
        }
    }, []);

    const redux = useSelector((state) => state);
    const reduxString = redux.token.permissions;

    if(!localStorage.getItem("token"))
    {
        return <Unauthorized />
    }
    else
    {
        return children;
    }
};

export default LoginChecker;
