// Import necessary components and libraries
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {Link, useNavigate, useParams} from 'react-router-dom';
import { config } from '../../config';
import ReactMarkdown from 'react-markdown';
import {EyeInvisibleOutlined, EyeOutlined, LoadingOutlined, PlusOutlined, UploadOutlined} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { Button, ConfigProvider, Form, Input, theme, Row, Col, Select, Upload, Tabs, Modal, Image, notification } from "antd";
import {MDXProvider} from "@mdx-js/react";
import Markdown from "markdown-to-jsx";
import {error, success} from "../common/toastr";
import {TextField} from "@mui/material";
const { Option } = Select;
const { TabPane } = Tabs;
const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const AddPost = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 800); // Set initial value based on screen width

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 800);
        };

        // Attach the event listener
        window.addEventListener("resize", handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState([]);
    const [activeTab, setActiveTab] = useState('editor'); // 'editor' or 'preview'

    const uploadButton = (
        !isMobile ? <button
            style={{
                border: 0,
                background: 'none',
                color: "#ffffff",
                cursor: 'pointer'
            }}
            type="button"
        >
            <PlusOutlined/>
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button> : <Button
            icon={<UploadOutlined />}
        >
            Upload
        </Button>
    );

    const onFinish = (values) => {
        axios.post(config.baseUrl + "/blog", {
            title: values.title,
            content: values.post,
            topic: values.topic,
        }, {
            headers: {
                token: localStorage.getItem("token")
            }
        }).then(response => {
            // // console.log("Response", response.data.success)
            if (response.data.success === true)
            {
                success("Posted successfully")
                navigate("/blog/" + response.data.postId)
            }
            else {
                error("Something went wrong")
            }
        }).catch(exception => {
            console.error(exception)
            error(exception)
        })
    }


    const handleChange = ({ fileList: newFileList, file }) => {
        setFileList(newFileList);
        if (file?.status === 'done') {
            // File is uploaded successfully
            // // console.log(file?.response);
            form.setFieldValue("post", (form.getFieldValue("post") ? form.getFieldValue("post") : "") + "![](" + config.baseUrl + "/images/" + file?.response.id + ")")
        }
    }

    const handleDelete = (file) => {
        // // console.log("File : ", file)
        axios.delete(config.baseUrl + "/images/" + file?.response.id, {
            headers: {
                token: localStorage.getItem("token")
            }
        }).then(response => {
            if (response.data.deleted === true) {
                success("Image Deleted Successfully");
            } else {
                error("Something went wrong");
            }
        });
    };


    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    // console.log("File list", fileList)

    return (
        <div>
            <Form
                size={"default"}
                colon={false}
                form={form}
                name="post-form"
                className="post-form"
                onFinish={onFinish}
                requiredMark={false}
                layout='vertical'
            >
                <Row gutter={8}>
                    <Col xs={24} sm={18}>
                        <Form.Item
                            label="Title"
                            name="title"
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter the title, bozo!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={6}>
                        <Form.Item
                            label="Topic"
                            name="topic"
                            rules={[
                                {
                                    required: true,
                                    message: 'Natural selection should\'ve taken you out.',
                                },
                            ]}
                        >
                            <Select>
                                <Option value="Technology">Technology</Option>
                                <Option value="Gaming">Gaming</Option>
                                <Option value="Programming">Programming</Option>
                                <Option value="Misc.">Misc.</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Tabs activeKey={activeTab} onChange={(key) => setActiveTab(key)}>
                    <TabPane tab="Editor" key="editor">
                        <Form.Item
                            name="post"
                            rules={[
                                {
                                    required: true,
                                    message: 'Are you dumb? Do you want people to not read anything? Actually, now that I think about it, its probably a good idea.',
                                },
                            ]}
                        >
                            <TextArea style={{ height: 300 }} />
                        </Form.Item>
                    </TabPane>
                    <TabPane tab="Preview" key="preview">
                        <Form.Item name="preview">
                            <div style={{ width: "100%", maxWidth: "750px", color: "#cccccc", minHeight: "300px" }}>
                                <p style={{width: "100%", wordWrap: "break-word"}}>
                                    <Markdown
                                        options={{
                                            overrides: {
                                                img: ({alt, src}) => (
                                                    <img
                                                        alt={alt}
                                                        src={src}
                                                        style={{maxWidth: '100%', maxHeight: "400px"}}
                                                    />
                                                ),
                                            },
                                        }}
                                    >
                                        {form.getFieldValue('post') ? form.getFieldValue('post') : ""}
                                    </Markdown>
                                </p>
                            </div>
                        </Form.Item>
                    </TabPane>


                </Tabs>

                <Form.Item
                    label="Images"
                    name="images"
                >
                    <Upload
                        action={config.baseUrl + "/images/upload"}
                        listType={!isMobile ? "picture-card" : "picture"}
                        fileList={fileList}
                        onPreview={handlePreview}
                        onChange={handleChange}
                        onRemove={handleDelete}
                        headers={{ // Add the headers object here
                            token: localStorage.getItem("token") // Assuming you want to send a token header
                        }}
                    >
                        {uploadButton}
                    </Upload>
                    <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}             centered={true}>
                        <img
                            alt="example"
                            style={{
                                display: 'block',
                                maxWidth: '100%',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                maxHeight: '75dvh',
                                alignItems: 'center'
                            }}
                            src={previewImage}
                        />
                    </Modal>
                </Form.Item>

                <Form.Item>
                    <Button htmlType="submit" className="post-button">
                        Post
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default AddPost;
